import React from "react";


const RidesList = () => {
    return (
        <>
        <h1>User Rides</h1>
        </>
    )
}

export default RidesList;
import React, { useState, useEffect } from 'react';
import './models.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { viewBrandModels } from '../../../../helpers/Redux/api'; // Adjust the import path

const BrandModels = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentModels, setCurrentModels] = useState([]);
  const [oldModels, setOldModels] = useState([]);
  const [brandDetails, setBrandDetails] = useState({});

  const brand_id = location.pathname?.split("/")[3];

  const handleGetData = () => {
    try {
      dispatch(viewBrandModels(brand_id)).then((res) => {
        if (res?.payload?.status === true) {
          const payload = res.payload.payload;
          setCurrentModels(payload.currentModels || []);
          setOldModels(payload.oldModels || []);
          setBrandDetails(payload.brandDetails || {});
        } else {
          console.log("Failed to fetch data");
        }
      });
    } catch (error) {
      console.log(error, "from view Brand details");
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <div className='model_list'>
        <div className='row'>
            <div className='col-md-6'>
                <h4>Current Models</h4>
                {currentModels.length > 0 ? (
                <ul>
                {currentModels.map(model => (
                    <li key={model.id}>{model.model_name}</li>
                ))}
                </ul>
            ) : (
                <p>No current models available.</p>
            )}
            </div>

            <div className='col-md-6'>
                <h4>Old Models</h4>
                {oldModels.length > 0 ? (
                <ul>
                {oldModels.map(model => (
                    <li key={model.id}>{model.model_name}</li>
                ))}
                </ul>
            ) : (
                <p>No old models available.</p>
            )}

            </div>
        </div>
    </div>
  );
};

export default BrandModels;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ClubRequestById } from "../../../helpers/Redux/api";
import ProfilePhoto from "../.../../../helper/PhotoProfiler/model";
import header_image_thumg from "../../../assets/images/users/noimg.png";

const ClubRequestView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [clubDetails, setClubDetails] = useState({});
  const [showImage, setShowImage] = useState(header_image_thumg);
  const [loading, setLoading] = useState(true);
  const clubId = location.pathname?.split("/")[3];
  const previousPageCount = location.state?.page;

  const fetchClubDetails = async () => {
    setLoading(true);
    try {
      const response = await dispatch(ClubRequestById(clubId));
      if (response?.payload?.status === true) {
        const clubData = response.payload.data;
        setClubDetails(clubData);
        if (clubData?.club_logo) {
          setShowImage(clubData.club_logo);
        }
      } else {
        setClubDetails({});
      }
    } catch (error) {
      console.error("Error fetching club details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClubDetails();
  }, [dispatch, clubId]);

  return (
    <div className="list_page_container">
      <div className="page_name_heading_cont">
        <div className="container">
          <h5>Requested Club Information</h5>
        </div>
      </div>
      <div className="py-5">
        <div className="container">
          <div className="shadow-box">
            <div className="custom_tabs">
              <section className="add_section">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div className="view_information_cont_inner">
                    {clubDetails.name && (
                      <div className="information_details_cont">
                        <label className="label">Club Name</label>:
                        <p className="details">{clubDetails?.name}</p>
                      </div>
                    )}
                    {clubDetails.club_start_date && (
                      <div className="information_details_cont">
                        <label className="label">Start Date</label>:
                        <p className="details">{new Date(clubDetails?.club_start_date).toLocaleDateString()}</p>
                      </div>
                    )}
                    {clubDetails.club_headquarters_name && (
                      <div className="information_details_cont">
                        <label className="label">Headquarters</label>:
                        <p className="details">{clubDetails?.club_headquarters_name}</p>
                      </div>
                    )}
                    {clubDetails.address && (
                      <div className="information_details_cont">
                        <label className="label">Address</label>:
                        <p className="details">{clubDetails?.address}</p>
                      </div>
                    )}
                    {clubDetails.description && (
                      <div className="information_details_cont">
                        <label className="label">Description</label>:
                        <p className="details">{clubDetails?.description}</p>
                      </div>
                    )}
                    {clubDetails.user_name && (
                      <div className="information_details_cont">
                        <label className="label">User Name</label>:
                        <p className="details">{clubDetails?.user_name}</p>
                      </div>
                    )}
                    {clubDetails.user_email && (
                      <div className="information_details_cont">
                        <label className="label">User Email</label>:
                        <p className="details">{clubDetails?.user_email}</p>
                      </div>
                    )}
                    {clubDetails.user_mobile && (
                      <div className="information_details_cont">
                        <label className="label">User Mobile</label>:
                        <p className="details">{clubDetails?.user_mobile}</p>
                      </div>
                    )}
                    <div className="information_details_cont">
                      <label className="label">Club Logo</label>:
                      <div className="image-container">
                        <ProfilePhoto src={showImage} />
                      </div>
                    </div>
                    <div className="my-4">
                      <button
                        className="btn btn-success"
                        onClick={() =>
                          navigate("/clubRequests", { state: { previousPageCount } })
                        }
                      >
                        Back
                      </button>
                    </div>
                  </div>
                )}
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubRequestView;

import React, {  useState } from "react";
import {  useNavigate } from "react-router-dom";
import {
  Navbar,
  Collapse,
  Nav,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";
import user1 from "../assets/images/users/user-new.png";
import logo_dark from "../assets/Homepage_image/logo.svg";

const Header = () => {

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const logout = () => {
    navigate("/");
    localStorage.clear("token")
    localStorage.clear("user_id")
    localStorage.clear("userType")
  };



  const navigateProfile = () => {
    if (localStorage.getItem("userType") == "vendor") { navigate("/vendor/edit") }
    else if (localStorage.getItem("userType") == "clubs") { navigate("/club/edit") }
    else { navigate("/admin/edit") }
  }
 
  return (
    <Navbar color="white" dark expand="md" className="nav_bar">
      <div className="d-flex align-items-center">
        <NavbarBrand href="/" className="d-lg-none">
        <img src={logo_dark} alt="brand logo" className="header_logo" height={20} />
        </NavbarBrand>
        <Button color="primary" className="d-lg-none" onClick={() => showMobilemenu()}>
          <i className="bi bi-list"></i>
        </Button>
      </div>


     
      <div className="hstack gap-2">
        <Button color="primary" size="sm" className="d-sm-block d-md-none" onClick={Handletoggle}>
          {isOpen ? (
            <i className="bi bi-x"></i>
          ) : (
            <i className="bi bi-three-dots-vertical"></i>
          )}
        </Button>
      </div>
      <Collapse navbar isOpen={isOpen}>
        <Nav className="me-auto" navbar>
         
          <UncontrolledDropdown inNavbar nav>
            
          </UncontrolledDropdown>
        </Nav>
     
        <div className="mx-2 pl-2 pr-2"/>

        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle color="white" className="d-flex flex-row-reverse align-items-center gap-2 py-1">
            <img src={user1} alt="profile" className="rounded-circle" width="35" />
           
          </DropdownToggle>
          <DropdownMenu>
           
            <DropdownItem onClick={() => navigateProfile()}>Profile</DropdownItem> 
            <DropdownItem onClick={logout}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Collapse>
    </Navbar>
  );
};

export default Header;


import React from "react";
import commit_member from "../../../assets/Homepage_image/commit-members.png";
import security from "../../../assets/Homepage_image/security.svg";
import expert from "../../../assets/Homepage_image/expert.svg";
import data from "../../../assets/Homepage_image/data.svg";





import { useNavigate } from "react-router-dom";

const HomepageCommitment = () => {
    const navigate = useNavigate();
    return (
        <>
       <section id="commitment" className="pb-0">
        <div className="container">
            <div className="section-title text-center">
                <h2><span>Commitment</span> to our members</h2>
            </div>
            <div className="row gx-md-5 align-items-center justify-content-center">
                <div className="col-xl-7 col-lg-5 col-10">
                    <img src={commit_member} alt="" className="img-fluid"/>
                </div>
                <div className="col-xl-5 col-lg-7">
                    <div className="commit-box">
                        <img src={data} alt="" className="img-fluid"/>
                        <div className="commit-box-content">
                            <h4>DATA</h4>
                            <p>We do our best to encrypt all data and transactions to ensure a secure experience for our users.</p>
                        </div>
                    </div>

                    <div className="commit-box">
                        <img src={security} alt="" className="img-fluid"/>
                        <div className="commit-box-content">
                            <h4>SECURITY STORAGE</h4>
                            <p>Our engineers and security team ensure all your data is stored securly behind firewalls.</p>
                        </div>
                    </div>

                    <div className="commit-box">
                        <img src={expert} alt="" className="img-fluid"/>
                        <div className="commit-box-content">
                            <h4>EXPERT SUPPORT</h4>
                            <p>connect with us by sending email us at <a href="mailto:support@mytra.club">support@mytra.club</a> or go to support section in our app.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    )
}

export default HomepageCommitment;
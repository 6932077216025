import React, {useState, useEffect} from "react";
// import './list.css';
import noimage from "../../../assets/images/users/noimg.png"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ColorLists, colorStatusUpdate, ColorDelete } from "../../../helpers/Redux/api";
import Tooltip from '@mui/material/Tooltip';
import Pagination from "react-mui-pagination";
import { IoMdEye } from "react-icons/io";
import { ImBin2 } from "react-icons/im";
import { MdModeEdit } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { SketchPicker } from "react-color";

const ColorList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [list, setList] = useState([])
    const [total_count , setTotalCount] = useState(0)
    const [page , setPage] = useState(1)
    const [recordperpage, setRecordperpage] = useState(10)
    const [search, setSearch] = useState("")
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const [color, setColor] = useState('');
    
    const handleUpdateStatus = ( status , id) =>{
        const sendStatus =( status == true ? false : true)
        console.log(id)
        var requestOptions = {
             id : id,
            status :sendStatus
        }
        try {
            dispatch(colorStatusUpdate(requestOptions)).then((res) => {
                if (res?.payload?.status === true) {
                    getUsersData();
                    toast.success(res?.payload?.message);
                } else {
                    toast.error(res?.payload?.message);
                }
            });

        } catch (error) {
            console.log(error , 'error')
        }
    }

    const getUsersData = () =>{
        const requestOption = {
             page:page , 
            limit: recordperpage,
            search:search,
            vendor_id:null
        }
        if(localStorage.getItem('userType')=="vendor"){
            requestOption.vendor_id=localStorage.getItem('user_id')
        }
        try {
            dispatch(ColorLists(requestOption)).then((res)=>{
                if(res?.payload?.status === true) {
                    setList(res?.payload?.data)
                    setTotalCount(res?.payload?.totalCount)
                }
                else {
                    setList([])
                }
            })

        } catch (error) {
            console.log('user list Error :- ', error)
        }
    }
    useEffect(() => {
        getUsersData();
    }, [page,search])
    
    const handleDelete = (id) =>{
        
        Swal.fire({
            title: "Are you sure want to delete vendor?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0373FF",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete "
          }).then((result) => {
            if (result.isConfirmed) {

                try {
                    dispatch(ColorDelete(id)).then((res)=>{
                       if(res?.payload?.status === true){
                        // Swal.fire({
                        //     title: "Deleted!",
                        //     text: "Member Deleted successfully.",
                        //     icon: "success"
                        //   });
                        toast.success(res?.payload?.message)

                        setList(list?.filter((elm)=> elm?.id != id))
                       } else {
                        toast.error(res?.payload?.message)
                       }
                    }) 
        
                } catch (error) {
                    console.log(error , 'error')
                }
             
            }
        });
    }
    return (
        <>
        <div className="list_page_container">
            <div className="page_name_heading_cont">
                <div className='container'>
                    <h5>Colors</h5>
                </div>
            </div>
            <div className="py-md-5">
                <div className="container">
                    <div className="shadow-box">
                        <div class="top_search d-flex justify-content-between text-end mb-4">
                            <button className="btn btn-success m-0" onClick={() => navigate('/colors/create')} > + Add Color</button>
                            {/* <input type="text" placeholder="Type here..." onChange={(e)=> setSearch(e.target.value)} value={search}></input> */}
                        </div>
                        <div className="table_container table-responsive">
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th>Color</th>
                                        <th>Color Code</th>
                                        <th>Color Name </th>
                                        <th>Vendor Name </th>
                                        <th>Category</th>
                                        <th>Status</th>
                                        <th className="text-center" >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {  list?.map((res ,i)=>(
                                        <tr key={i} >
                                            <td>
                                                <div style={{ borderRadius: '50px', width: '30px', height: '30px', backgroundColor: res?.color_code || '#FFFFFF', border: '1px solid #ccc',}}></div>
                                            </td>

                                            <td className="common_class_td_width">{res?.color_code}</td>
                                            <td className="common_class_td_width">{res?.color_name}</td>
                                            <td className="common_class_td_width">{res?.name}</td>
                                            <td className="common_class_td_width">{res?.category_name}</td>
                                            <td>
                                                <Tooltip title="Click to change status">
                                                <button className={`${res?.is_active ? 'active' : 'inactive'}`}
                                                onClick={() => handleUpdateStatus(res?.is_active , res?.id)}> {res?.is_active ? 'Active' :'Inactive'} </button>
                                                </Tooltip>
                                            </td>
                                            <td>
                                                <div className="action_button_cont">
                                                    {/* <Tooltip title="Edit">
                                                        <button className="edit_btn" onClick={() => navigate(`/color/edit/${res?.id}`)} ><MdModeEdit /></button>
                                                    </Tooltip>
                                                    <Tooltip title="View">
                                                        <button className="view_btn" onClick={() => navigate(`/color/view/${res?.id}`, { state: { page } })} ><IoMdEye /></button>
                                                    </Tooltip> */}
                                                    <Tooltip title="Delete">
                                                        <button className="delete_btn" onClick={() => handleDelete(res?.id)} ><ImBin2 /></button>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                        {total_count >= 10 && 
                            <div className="d-flex   justify-content-end align-items-center my-3 ">
                                <Pagination  page={page} setPage={handlePageChange}
                                total={total_count} 
                                perPage={recordperpage} 
                                className='pagination'  />
                            </div>
                        }
                    </div>    
                </div>
            </div>
        </div>
        </>
    )
}

export default ColorList;
import React from "react";
import { Navigate } from "react-router-dom";

const Protected = ({ path, element: Component, loginPath }) => {
  const isAuthenticated = !!localStorage.getItem("token");

  return isAuthenticated ? (
    <Component />
  ) : (
    <Navigate to={loginPath} />
  );
};

export default Protected;
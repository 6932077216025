import React, {useState, useEffect} from "react";
import './list.css';
import noimage from "../../../assets/images/users/noimg.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BrandDelete, brandStatusUpdate, BannerImageLists, BannerImageDelete } from "../../../helpers/Redux/api";
import Tooltip from '@mui/material/Tooltip';
import Pagination from "react-mui-pagination";
import { ImBin2 } from "react-icons/im";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import AddBannerImage from "../Add/add";

const BannerImageList = () => {
    const dispatch = useDispatch();
    const [list, setList] = useState([])
    const [total_count , setTotalCount] = useState(0)
    const [page , setPage] = useState(1)
    const [recordperpage, setRecordperpage] = useState(10)
    const [search, setSearch] = useState("")
    const [updateApi, setUpdateApi] = useState("")
    const handlePageChange = (event, value) => {
        setPage(value);
    };  

    const getUsersData = () =>{
        const requestOption = {
             page:page , 
            limit: recordperpage,
            search:search
        }
        try {
            dispatch(BannerImageLists(requestOption)).then((res)=>{
                if(res?.payload?.status === true) {
                    setList(res?.payload?.payload)
                    setTotalCount(res?.payload?.totalCount)
                    setUpdateApi("")
                }
                else {
                    setList([])
                }
            })

        } catch (error) {
            console.log('user list Error :- ', error)
        }
    }
    useEffect(() => {
        getUsersData();
    }, [page,updateApi,search])
    
    const handleDelete = (id) =>{
        
        Swal.fire({
            title: "Are you sure want to delete banner image?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0373FF",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete "
          }).then((result) => {
            if (result.isConfirmed) {

                try {
                    dispatch(BannerImageDelete(id)).then((res)=>{
                       if(res?.payload?.status === true){
                        toast.success(res?.payload?.message)

                        setList(list?.filter((elm)=> elm?.id != res?.payload?.data.id))
                       } else {
                        toast.error(res?.payload?.message)
                       }
                    }) 
        
                } catch (error) {
                    console.log(error , 'error')
                }
             
            }
          });

       
    }
  
    return (
        <>

        <div className="list_page_container">
            <div className="page_name_heading_cont">
                <div className='container'>
                    <h5>Banner Images</h5>
                </div>
            </div>
            <div className="py-md-5">
                <div className="container">
                    <div className="shadow-box">
                        <div class="top_search d-flex justify-content-between text-end mb-4">
                            <AddBannerImage setUpdateApi={setUpdateApi} />
                        </div>
                        <div className="table_container table-responsive">
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Type</th>
                                        <th className="text-end" >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {  list?.map((res ,i)=>(
                                        <tr key={i} >
                                            <td> <img src={res?.image_url ? 
                                                res.image_url  
                                                : noimage} alt="profile_image" className="banner_image " />
                                            </td>

                                            <td>{ res.type }</td>
                                            
                                            <td className="text-end">
                                                <div className="action_button_cont justify-content-end">
                                                    <Tooltip title="Delete">
                                                        <button className="delete_btn" onClick={() => handleDelete(res?.id)} ><ImBin2 /></button>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                        {total_count >= 10 && 
                            <div className="d-flex   justify-content-end align-items-center my-3 ">
                                <Pagination  page={page} setPage={handlePageChange}
                                total={total_count} 
                                perPage={recordperpage} 
                                className='pagination'  />
                            </div>
                        }
                    </div>    
                </div>
            </div>
            </div>
        </>
    )
}

export default BannerImageList;
import React, { useEffect, useState } from "react";
import "./edit.css";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import header_image_thumg from "../../../../src/assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  clubUpdate,
  memberExcelImport,
  viewClubDetail,
} from "../../../helpers/Redux/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import * as moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
const EditCLub = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const previousPageCount = location.state?.page;
  const [showimage, setShowImage] = useState(header_image_thumg);
  const [startDate, setStartDate] = useState(new Date());
  const [sendImage, setSendImage] = useState(null);
  const [status, setstatus] = useState(0);

  const [loading, setLoading] = useState(false);

  const clubId = location.pathname?.split("/")[3];

  const handleImageUpload = (e) => {
    let file = e.target.files[0];
    setShowImage(URL.createObjectURL(file));
    setSendImage(file);
  };

  const [initialValue, setInitialValue] = useState({
    clubName: "",
    email: "",
    startDate: "",
    headquartersName: "",
    registrationNumber: "",
    address: "",
    description: "",
    // chatRestriction: 0,
  });

  const handlegetdata = () => {
    setLoading(true);
    try {
      dispatch(viewClubDetail(clubId)).then((res) => {
        if (res?.payload?.status === true) {
          setLoading(false);
          setInitialValue({
            clubName: res?.payload?.data.name,
            email: res?.payload?.data.email,
            startDate: res?.payload?.data.club_start_date,
            headquartersName: res?.payload?.data.club_headquarters_name,
            registrationNumber: res?.payload?.data.registration_no,
            address: res?.payload?.data.address,
            description: res?.payload?.data.description,
            // chatRestriction: res?.payload?.data.chat_restriction,
          });
          if (res?.payload?.data?.club_logo) {
            setShowImage(res?.payload?.data?.club_logo);
            setSendImage(res?.payload?.data?.club_logo);
          }
        } else {
          setLoading(false);
          setInitialValue(initialValue);
        }
      });
    } catch (error) {
      console.log(error, "from view Club details");
    }
  };
  useEffect(() => {
    handlegetdata();
  }, []);

  const addon = "mytra-";

  const handleSubmit = (values, { resetForm }) => {
    console.log(sendImage, "sendImage");
    const formData = new FormData();
    const formattedStartDate = values.startDate
      ? moment(values.startDate).toISOString()
      : ""; 
    formData.append("clubName", values.clubName);
    formData.append("email", values.email);
    formData.append("startDate", values.startDate); 
    formData.append("headquarterName", values.headquartersName);
    formData.append("address", values.address);
    formData.append("description", values.description);
    formData.append("club_logo", sendImage); // Append the image file

    // Create request options with the FormData
    const requestOptions = {
      clubId: clubId,
      body: {
        method: "POST",
        body: formData,
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      },
    };

    try {
      dispatch(clubUpdate(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          navigate(-1);
          resetForm();
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      updateNewExcel(file);
    }
  };

  const updateNewExcel = (json) => {
    const formData = new FormData();
    formData.append("file", json);

    const requestOption = {
      method: "POST",
      body: formData,
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    };

    try {
      dispatch(memberExcelImport(requestOption)).then((res) => {
        if (res?.isError == false) {
          toast.success(res?.message);
          if (res?.data?.duplicateMobiles.length > 0) {
            toast.error(
              ` ${res?.data?.duplicateMobiles.length} Wrong data  download in new Excel.`
            );
            const headers = ["Number", "clubId", "Error"];

            // Map data to a new array with custom headers
            const dataWithCustomHeaders = [
              headers,
              ...res?.data?.duplicateMobiles.map((item) => [
                item.mobile,
                item?.club_id,
                item.error,
              ]),
            ];
            // Generate Excel file
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            const excelBuffer = XLSX.write(workbook, {
              bookType: "xlsx",
              type: "array",
            });
            // Save file
            const fileData = new Blob([excelBuffer], {
              type: "application/octet-stream",
            });
            saveAs(fileData, "Errordata.xlsx");
          } else {
            // Display an alert when errorArray is empty
            toast.success("No error data found. All data upload Successfully");
          }
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      return error;
    }
  };

  const handleTemplateDownload = () => {
    // Custom headers
    const headers = ["mobile", "clubId"];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [headers, []];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "membersTemplate.xlsx");
  };

  const validationscheme = Yup.object({
    clubName: Yup.string().required(" Club name is required"),
    email: Yup.string()
      .required(" Email is required")
      .email(" Invalid email address"),
    // registrationNumber: Yup.string().required(" Registration number is required"),
    headquartersName: Yup.string().required(" City name is required"),
    address: Yup.string().required(" Address is required"),
    description: Yup.string().required(" Description is required"),
  });

  return (
    <div className="edit-section">
      <div className="page_name_heading_cont">
        <div className="container">
          <h5>Edit Club Details</h5>
        </div>
      </div>
      <div className="py-5">
        <div className="container">
          <div className="edit-form shadow-box">
            <div class="top_search mb-5">
              <button
                className="btn btn-success m-0"
                onClick={() => handleTemplateDownload()}
              >
                Download file
              </button>
            </div>
            {!loading && (
              <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                validationSchema={validationscheme}
              >
                {(formik) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <label htmlFor="clubName">Club Title</label>
                        <Field
                          className="form-control"
                          placeholder="Club Name"
                          name="clubName"
                        />
                        <ErrorMessage
                          name="clubName"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label htmlFor="email">Email</label>
                        <Field
                          className="form-control"
                          name="email"
                          placeholder="Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label htmlFor="startDate">Start Date</label>
                        <DatePicker
                          className="form-control w-100"
                          selected={
                            formik.values.startDate
                              ? new Date(formik.values.startDate)
                              : null
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              "startDate",
                              moment(date).format("YYYY-MM-DD")
                            ); // Use proper format
                            setStartDate(date);
                          }}
                          dateFormat="yyyy-MM-dd"
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label htmlFor="headquartersName">City</label>
                        <Field
                          className="form-control"
                          name="headquartersName"
                          placeholder="City name"
                        />
                        <ErrorMessage
                          name="headquartersName"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-md-6 mb-4">
                        <label htmlFor="address">Address</label>
                        <Field
                          className="form-control"
                          name="address"
                          placeholder="Address"
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-md-6 mb-4">
                        <label htmlFor="importFile">Import Members File</label>
                        <input
                          className="form-control"
                          type="file"
                          onChange={handleFileUpload}
                        />
                      </div>

                      <div className="col-md-12 mb-4">
                        <label htmlFor="description">Description</label>
                        <Field
                          className="form-control"
                          name="description"
                          component="textarea"
                          rows="4"
                          placeholder="Description"
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="upload_image_container">
                          <img
                            src={showimage}
                            alt="logo"
                            className="img-fluid uploaded_image "
                          />
                          <label htmlFor="upload-photo1">Upload Photo</label>
                          <input
                            type="file"
                            name="clubLogo"
                            id="upload-photo1"
                            onChange={handleImageUpload}
                            accept=".pjg, .png, .jpeg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-3">
                        <button
                          type="submit"
                          name="back"
                          className="btn btn-success w-100 mt-4"
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          className="btn btn-secondary w-100 mt-4"
                          onClick={() =>
                            navigate("/clubs", { state: { previousPageCount } })
                          }
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCLub;

import React from "react";
import shop_with_us from "../../../assets/Homepage_image/shop-with-us.png"

const HomePageShop  = () => {
    return (
        <section id="shop-with-us" className="pb-0">
        <div className="container-fluid px-0">
            <div className="section-title text-center px-4">
                <h2><span>Shop with us | </span> <small>Gear & Spares</small></h2>
            </div>
            <img src={shop_with_us} alt="" className="img-fluid"/>
        </div>
    </section>
    )
}
export default HomePageShop
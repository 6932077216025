import React, { useState } from "react";
import "./add.css";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import header_image_thumg from "../../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { vendorAdd } from "../../../helpers/Redux/api";

const AddVendor = () => {
    console.log("Call");
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const previousPageCount = location.state?.page;
    const [showimage, setShowImage] = useState(header_image_thumg);
    const [startDate, setStartDate] = useState(new Date());

    const [sendImage, setSendImage] = useState("");

    const handleImageUpload = (e) => {
        var file = e.target.files[0];
    setSendImage( e.target.files[0]);
        
    setShowImage(URL.createObjectURL(file));
    };

    const handleSubmit = (values, { resetForm }) => {

        // Create a new FormData object
        const formData = new FormData();

        // Append form values to FormData
        formData.append('name', values.vendorName);
        formData.append('email', values.email);
        formData.append('address', values.address);
        formData.append('password', values.password);
        formData.append('zip_code', values.zipCode);
        formData.append('mobile', values.mobile);
        formData.append('gst_no', values.gstNo);
        formData.append('gst_charge', values.gstCharge);
        formData.append('pan_card', values.panCard);
        formData.append('chatRestriction', values.chatRestriction);
        formData.append('vendor_logo', sendImage); // Append the image file


        // Create request options with the FormData
        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: {
        'authorization' :`${localStorage.getItem('token')}`
                
            }
        };
        try {
            dispatch(vendorAdd(requestOptions)).then((res) => {
                console.log(res);
                if (res?.payload?.status === true) {
                    toast.success(res?.payload?.message);
                    navigate(-1)
                    resetForm()
                } else {
                    toast.error(res?.payload?.message);
                }
            });

        } catch (error) {
            console.log(error , 'error')
        }
    }

    const validationscheme = Yup.object({
        vendorName: Yup.string().required(" Name is required"),
        email: Yup.string().required("Email is required").email("Invalid email address"),
        address: Yup.string().required(" Address  is required"),
        password: Yup.string().required(" Password  is required"),
        zipCode: Yup.string().required("Zip code is required"),
        mobile: Yup.string().required("Phone is required"),
        gstNo: Yup.string().required("GST No is required"),
        gstCharge: Yup.string().required("GST Charge is required"),
        panCard: Yup.string().required("Pan card is required"),
        // vendorLogo: Yup.string().required("Pan card is required"),
      });
    
    return (
      <div className="edit-section">
        <div className="page_name_heading_cont">
            <div className='container'>
                <h5>Add Vendor</h5>
            </div>
        </div>
        <div className='py-5'>
            <div className='container'>
                <div className="edit-form shadow-box">
                <Formik
                    initialValues={{
                        vendorName: '',
                        email: '',
                        address: '',
                        password: '',
                        zipCode: '',
                        mobile: '',
                        gstNo: '',
                        gstCharge: '',
                        panCard: '',
                        chatRestriction: 0,
                        vendorLogo: '',
                    }}
                    onSubmit={ handleSubmit}
                    validationSchema={validationscheme}
                >
                    {(formik)=> (
                    <Form>
                        <div className='row'>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="vendorName">Vendor Name</label>
                                <Field className="form-control" placeholder="Your Vendor Name" name="vendorName" required="" />
                                <ErrorMessage name='vendorName' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="email">Email</label>
                                <Field className="form-control" placeholder="Email" name="email" required="" />
                                <ErrorMessage name='email' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="address">Address</label>
                                <Field className="form-control" placeholder="Address" name="address" required="" />
                                <ErrorMessage name='address' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="password">Password</label>
                                <Field className="form-control" placeholder="Password" name="password" required="" />
                                <ErrorMessage name='address' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="zipCode">Zip code</label>
                                <Field className="form-control" placeholder="Zip Code" name="zipCode" required="" />
                                <ErrorMessage name='zipCode' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="mobile">Phone</label>
                                <Field className="form-control" placeholder="Phone" name="mobile" required="" />
                                <ErrorMessage name='mobile' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="gstNo">GST No.</label>
                                <Field className="form-control" placeholder="GST no." name="gstNo" required="" />
                                <ErrorMessage name='gstNo' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="gstCharge">GST Charge</label>
                                <Field className="form-control" placeholder="GST Charge" name="gstCharge" required="" />
                                <ErrorMessage name='gstCharge' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="panCard">Pan card</label>
                                <Field className="form-control" placeholder="Pan card" name="panCard" required="" />
                                <ErrorMessage name='panCard' component="div" className="text-danger" />
                            </div>
                            {/* <div className='col-md-12 mb-4'>
                                <label htmlFor="zipCode">Description</label>
                                <Field className="form-control" name="description" component="textarea" rows="4" placeholder="Description" />
                            </div> */}
                            <div className="col-md-12">
                                <div className="d-flex flex-row gap-4 align-items-center ">
                                <label htmlFor="status">
                                    Chat Restriction {" "}
                                </label>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role='Switch'  id="flexSwitchCheckChecked" name="chatRestriction" checked={formik.values.chatRestriction == 1}  onChange={(e) =>  formik.setFieldValue('chatRestriction' ,e.target.checked ? 1 : 0)}  />
                                    <label className="form-check-label" for="flexSwitchCheckChecked"></label>
                                    </div>
                                </div>
                                {/* <ErrorMessage
                                    component="p"
                                    name="status"
                                    className="text-danger"
                                /> */}
                            </div>
                            <div className='col-md-6'>
                                <div className="upload_image_container">
                                    <img src={ showimage} alt="logo" className="img-fluid uploaded_image " />
                                    <label htmlFor="upload-photo2">Upload Photo</label>
                                    <input type="file" name="vendorLogo" id="upload-photo2" onChange={handleImageUpload} accept=".pjg, .png, .jpeg" />
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-4 g-3">
                            <div className="col-md-3">
                                <button type="submit" name="back" className="btn btn-success w-100 mt-4">Submit</button>
                            </div>
                            <div className="col-md-3">
                                <button type="button" className="btn btn-secondary w-100 mt-4" onClick={() => navigate("/vendors", { state: { previousPageCount } })}>Back</button>
                            </div>
                        </div>
                    </Form>
                    )}
                </Formik>
                </div>
            </div>    
        </div>
      </div>
    );
};
export default AddVendor;

import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import header_image_thumg from '../../assets/images/users/noimg.png';
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { editSetting, ViewSetting, viewVendorDetail } from "../../helpers/Redux/api";

const SettingView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [list, setList] = useState([])
    const [loading , setLoading] = useState(false)
    const [page , setPage] = useState(1)
    const [recordperpage, setRecordperpage] = useState(10)
    const [search, setSearch] = useState("")
    const [updateApi, setUpdateApi] = useState("")
    const handlePageChange = (event, value) => {
        setPage(value);
    };  

    const [initialValue, setInitialValue] = useState({
        contact_number: '',
        contact_email: '',
        app_version: '',
        individual_ride: '',
        individual_ride_point: '',
        group_ride: '',
        group_ride_point: '',
        club_ride: '',
        club_ride_point: '',
        rupees_per_point: '',
        points_per_rupee: '',
    });

    console.log(initialValue)

    const getUsersData = () =>{
        setLoading(true)
        const requestOption = {
             page:page , 
            limit: recordperpage,
            search:search
        }
        try {
            dispatch(ViewSetting(requestOption)).then((res)=>{
                setLoading(false)
                if(res?.payload?.status === true) {
                    setUpdateApi("")
                    setInitialValue({
                        contact_number: res?.payload?.payload[0].contact_number,
                        contact_email: res?.payload?.payload[0].contact_email,
                        app_version: res?.payload?.payload[0].app_version,
                        individual_ride: res?.payload?.payload[0].individual_ride,
                        individual_ride_point: res?.payload?.payload[0].individual_ride_point,
                        group_ride: res?.payload?.payload[0].group_ride,
                        group_ride_point: res?.payload?.payload[0].group_ride_point,
                        club_ride: res?.payload?.payload[0].club_ride,
                        club_ride_point: res?.payload?.payload[0].club_ride_point,
                        rupees_per_point: res?.payload?.payload[0].rupees_per_point,
                        points_per_rupee: res?.payload?.payload[0].points_per_rupee,
                  });
                }
                else {
                    setList([])
                }
            })

        } catch (error) {
            console.log('user list Error :- ', error)
        }
    }
    useEffect(() => {
        getUsersData();
    }, [page,updateApi,search])
    
    const handleSubmit = (values, { resetForm }) => {
        try {
          dispatch(editSetting(values)).then((res) => {
            if (res?.payload?.status === true) {
              toast.success(res?.payload?.message);
            //   getUsersData();
            } else {
              toast.error(res?.payload?.message);
            }
          });
    
        } catch (error) {
          console.log(error, 'error')
        }
    }

    return (
        <div className="edit-section">
            <div className="page_name_heading_cont">
                <div className='container'>
                    <h5>Setting</h5>
                </div>
            </div>
            <div className='py-5'>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className="col-md-7">
                            <div className="edit-form shadow-box">
                        {!loading &&
                            <Formik
                                initialValues={initialValue}
                                onSubmit={handleSubmit}
                                // validationSchema={validationscheme}
                            >
                                {(formik) => (
                                    <Form>
                                        <div className='row'>
                                            <div className='col-md-12 mb-4'>
                                                <label htmlFor="contact_number">Contact Number</label>
                                                <Field className="form-control" placeholder="Contact Name" name="contact_number" />
                                                <ErrorMessage name='contact_number' component="div" className="text-danger" />
                                            </div>
                                            <div className='col-md-12 mb-4'>
                                                <label htmlFor="contact_email">Contact Email</label>
                                                <Field className="form-control" placeholder="Contact Email" name="contact_email" />
                                                <ErrorMessage name='contact_email' component="div" className="text-danger" />
                                            </div>
                                            <div className='col-md-12 mb-4'>
                                                <label htmlFor="app_version">App Version</label>
                                                <Field className="form-control" placeholder="App Version" name="app_version" />
                                                <ErrorMessage name='app_version' component="div" className="text-danger" />
                                            </div>
 
                                            <div className='col-md-6 mb-4'>
                                                <label htmlFor="individual_ride">Individual Ride Km</label>
                                                <Field className="form-control" placeholder="Individual Ride Km" name="individual_ride" />
                                                <ErrorMessage name='individual_ride' component="div" className="text-danger" />
                                            </div>
                                            <div className='col-md-6 mb-4'>
                                                <label htmlFor="individual_ride_point">Point <small className="fw-normal">(User earns points)</small></label>
                                                <Field className="form-control" placeholder="Individual Ride Points" name="individual_ride_point" />
                                                <ErrorMessage name='individual_ride_point' component="div" className="text-danger" />
                                            </div>
                                            <div className='col-md-6 mb-4'>
                                                <label htmlFor="group_ride">Group Ride Km</label>
                                                <Field className="form-control" placeholder="Group Ride Km" name="group_ride" />
                                                <ErrorMessage name='group_ride' component="div" className="text-danger" />
                                            </div>
                                            <div className='col-md-6 mb-4'>
                                                <label htmlFor="group_ride_point">Point <small className="fw-normal">(User earns points)</small></label>
                                                <Field className="form-control" placeholder="Group Ride Points" name="group_ride_point" />
                                                <ErrorMessage name='group_ride_point' component="div" className="text-danger" />
                                            </div>
                                            <div className='col-md-6 mb-4'>
                                                <label htmlFor="club_ride">Club Ride Km</label>
                                                <Field className="form-control" placeholder="Club Ride Km" name="club_ride" />
                                                <ErrorMessage name='club_ride' component="div" className="text-danger" />
                                            </div>
                                            <div className='col-md-6 mb-4'>
                                                <label htmlFor="club_ride_point">Point <small className="fw-normal">(User earns points)</small></label>
                                                <Field className="form-control" placeholder="Club Ride Points" name="club_ride_point" />
                                                <ErrorMessage name='club_ride_point' component="div" className="text-danger" />
                                            </div>
                                            <div className='col-md-6 mb-4'>
                                                <label htmlFor="rupees_per_point">Rupees per point</label>
                                                <Field className="form-control" placeholder="Rupees per point" name="rupees_per_point" />
                                                <ErrorMessage name='rupees_per_point' component="div" className="text-danger" />
                                            </div>
                                            <div className='col-md-6 mb-4'>
                                                <label htmlFor="points_per_rupee">Points per rupee</label>
                                                <Field className="form-control" placeholder="Points per rupee" name="points_per_rupee" />
                                                <ErrorMessage name='points_per_rupee' component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-4 g-3">
                                            <div className="col-md-3">
                                                <button type="submit" name="back" className="btn btn-success w-100 mt-4">Submit</button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            }
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SettingView;

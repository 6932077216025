import React, { useState, useEffect } from "react";
import './add.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { brandAdd } from "../../../helpers/Redux/api";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
//   width: 400,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};


const AddBrand = ({setUpdateApi}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [inputList, setInputList] = useState([{ brandName: "", brandImage: "" }]);
    console.log(inputList)
    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    const handleImageUpload = (e, i) => {
        var file = e.target.files[0];
        
        const list = [...inputList];
        list[i].brandImage = file;
        setInputList(list);
    };


  // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        const remove = list.filter((_, indexFilter) => !(indexFilter === index));
        // list.splice(index, 1);
        setInputList(remove);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { brandName: "", brandImage: "" }]);
    };
        
    const handleSubmit = () => {

        // Create a new FormData object
        const formData = new FormData();

        inputList.forEach((res) => {
            formData.append('brand_name', res.brandName);
            formData.append('brand_logo', res.brandImage);
        });
        
        // Create request options with the FormData
        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: {
        'authorization' :`${localStorage.getItem('token')}`
                
            }
        };

        try {
            dispatch(brandAdd(requestOptions)).then((res) => {
                if (res?.payload?.status === true) {
                    toast.success(res?.payload?.message);
                    handleClose()
                    setUpdateApi("add")
                } else {
                    toast.error(res?.payload?.message);
                }
            });

        } catch (error) {
            console.log(error , 'error')
        }
    }
    
    return (
        <>
            <div className="">
                <button className="btn btn-success m-0" onClick={handleOpen} > + Add Brand</button>
            </div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style} className="brandModel">
                    <IoIosCloseCircleOutline className="close-icon" onClick={()=>handleClose()} /> 
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Brands
                    </Typography>
                    <div>
                        {inputList.map((x, i) => {
                            return (
                            <div className="box">
                                <div className="boxInner">
                                    <input type="text" name="brandName" placeholder="Enter brand name" value={x.brandName} onChange={(e) => handleInputChange(e, i)} />
                                    <input type="file" onChange={(e) => handleImageUpload(e, i)}/>
                                    <div className="close-brand-field">
                                        {inputList.length !== 1 && (
                                            <button className="mr10" onClick={() => handleRemoveClick(i)}><IoIosCloseCircleOutline /> </button>
                                        )}
                                    </div>
                                </div>
                                {/* <div className="btn-box text-end">
                                    {inputList.length - 1 === i && (
                                        <a className="btn btn-info mt-3 btn-sm" onClick={handleAddClick}>Add More</a>
                                    )}
                                </div> */}
                            </div>
                            );
                        })}
                    </div> 

                    <div className="text-center brandModelFooter border-top pt-4 mt-4">
                        <a className="btn btn-secondary me-3" onClick={handleClose}>Cancel</a>
                        <a className="btn btn-success" onClick={handleSubmit}>Save</a>
                    </div>
                </Box>
            </Modal>
       
        </>
    )
}

export default AddBrand;
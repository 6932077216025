import React, { useState, useEffect } from "react";
import './add.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { bannerImageAdd, brandAdd } from "../../../helpers/Redux/api";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { TiDeleteOutline } from 'react-icons/ti'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //   width: 400,
    bgcolor: 'background.paper',
    //   border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
};


const AddBannerImage = ({ setUpdateApi }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const validationSchemas = Yup.object({

        image: Yup.array()
            .of(
                Yup.mixed()
                    .test(
                        "fileFormat",
                        "Unsupported Format",
                        (value) => value && ["image/jpg", "image/jpeg", "image/png"].includes(value.type)
                    )
            )
            .test(
                "minLength",
                "You must upload at least 1 images",
                (value) => value && value.length >= 1
            )
            .test(
                "maxLength",
                "You can upload a maximum of 5 images",
                (value) => value && value.length <= 5
            )

    })



    const handleSubmit = (values, { resetForm }) => {

        // Create a new FormData object
        const formData = new FormData();
        console.log(values)
        values.image.forEach((res) => {
            formData.append('files', res);
        });
        formData.append('type', values.banner_for);
        formData.append('banner_link', values.banner_link);

        // Create request options with the FormData
        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: {
                'authorization': `${localStorage.getItem('token')}`
            }
        };

        try {
            dispatch(bannerImageAdd(requestOptions)).then((res) => {
                if (res?.payload?.status === true) {
                    toast.success(res?.payload?.message);
                    handleClose()
                    setUpdateApi("add")
                    resetForm()
                } else {
                    toast.error(res?.payload?.message);
                }
            });

        } catch (error) {
            console.log(error, 'error')
        }
    }

    return (
        <>
            <div className="">
                <button className="btn btn-success m-0" onClick={handleOpen} > + Add Banner Image</button>
            </div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style} className="brandModel">
                    <IoIosCloseCircleOutline className="close-icon" onClick={() => handleClose()} />
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Banner Image
                    </Typography>
                    <div>
                        <div className="box bannerImage">
                            <div className="boxInner">
                                <Formik initialValues={{ image: [], banner_for: "", banner_link: "" }} validationSchema={validationSchemas} onSubmit={handleSubmit}>
                                    {(formik) => (
                                        <Form>
                                            <Field className="form-control mb-3" as="select" name="banner_for" required>
                                                <option value="" label="Select" />
                                                <option value="Homescreen" label="Homescreen" />
                                                <option value="Marketplace" label="Marketplace" />
                                            </Field>
                                            <ErrorMessage name='banner_for' component="div" className="text-danger" />

                                            <Field className="form-control mb-3" placeholder="Add image link" name="banner_link" />
                                            <ErrorMessage name='banner_link' component="div" className="text-danger" />
                                            
                                            <input id="image" type="file" multiple accept=".jpg,.jpeg,.png" onChange={(e) => {
                                                const files = Array.from(
                                                    e.target.files);
                                                formik.setFieldValue("image", files);
                                            }}
                                            />
                                            <ErrorMessage name='image' component="div" className="text-danger" />

                                            <ul className="list-inline upload_image_thumbs mt-4">
                                                {
                                                    formik.values.image?.map((image, imageIndex) => (
                                                        <li className="list-inline-item" key={imageIndex}>
                                                            <div>
                                                                <img
                                                                    className="img-fluid rounded"
                                                                    src={URL.createObjectURL(image)}
                                                                    alt="example"
                                                                />
                                                            </div>
                                                            <TiDeleteOutline
                                                                className="delete_icons"
                                                                onClick={() => {
                                                                    const updatedVariantRow = formik.values.image.filter((_, idx) => idx !== imageIndex);
                                                                    formik.setFieldValue('image', updatedVariantRow);
                                                                }}
                                                            />
                                                        </li>
                                                    ))
                                                }

                                            </ul>

                                            <div className="text-center brandModelFooter border-top pt-4 mt-4">
                                                <a className="btn btn-secondary mt-0 me-3" onClick={handleClose}>Cancel</a>
                                                <button className="btn btn-success" type="submit">Save</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>

        </>
    )
}

export default AddBannerImage;
import React, { useEffect, useState } from "react";
import './events.css';
import noimage from "../../../../assets/images/users/noimg.png";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { viewClubDetail, viewGroupDetail } from "../../../../helpers/Redux/api";
import Moment from "react-moment";

const GroupEvents = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [events , setEvents] = useState([])

    const clubId = location.pathname?.split("/")[3];

    const handlegetdata = () => {
    //   setLoading(true);
      try {
        dispatch(viewClubDetail(clubId)).then((res) => {
         
          if (res?.payload?.status === true) {
            // setLoading(false);
            setEvents(res?.payload?.data?.events);
           
          } else {
            // setLoading(false);
            // setInitialValue(initialValue);
          }
        });
      } catch (error) {
        console.log(error, "from view Event details");
      }
    };
    useEffect(() => {
      handlegetdata();
    }, []);
    

return (
<>
<div className="grp-events-main">
  <div className="row">
    {
    events?.map((res) => (
    <div className="col-md-4" onClick={()=> navigate(`/event/view/${res._id}`)} >
      <div className="event-box">
        <h6>{res.title}</h6>
        <div class="post-box-media">
            <img src={res?.profile_picture ? `${res?.profile_picture}` : noimage} alt="event_image" className="img-fluid" />
        </div>

        <div className="d-flex align-items-center justify-content-between mt-3">
          <p className="mb-0">Date : <Moment format="MM/DD/YYYY">{res.startTime}</Moment>
          </p>
          <p className="mb-0">Members : {res.registeredUsers.length}</p>
        </div>
      </div>
    </div>
    ))
    }
  </div>
</div>


  <p className="btn btn-success mt-4" onClick={()=> navigate("/clubs")}>Back</p>
</>
)
}

export default GroupEvents;
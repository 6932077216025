import React, { Suspense, useEffect, useState } from "react";
import {  Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";

// routes config
import routes from "../../routes/Router";
import Dashboard from "../../views/Dashboard/dashboard";
import UserList from "../../views/Users/List/list";
import EventList from "../../views/Events/list/list";
import EditUser from "../../views/Users/Edit/edit";
import GroupList from "../../views/Groups/list/list";
import ProtectedRoute from "../../views/Users/View/tab";
import UserViewTabs from "../../views/Users/View/tab";
import RidesList from "../../views/Rides/list";
import GroupViewTabs from "../../views/Groups/View/tab";
import EditEvent from "../../views/Events/Edit/edit";
import EditGroup from "../../views/Groups/Edit/edit";
import EventViewTabs from "../../views/Events/View/tab";
import ClubList from "../../views/Clubs/List/list";
import AddClub from "../../views/Clubs/Add/add";
import ClubViewTabs from "../../views/Clubs/View/tab";
import EditCLub from "../../views/Clubs/Edit/edit";
import BrandList from "../../views/Brands/List/list";
import EditBrand from "../../views/Brands/Edit/edit";
import BrandViewTabs from "../../views/Brands/View/tab";
import VendorList from "../../views/Vendors/List/list";
import AddVendor from "../../views/Vendors/Add/add";
import VendorViewTabs from "../../views/Vendors/View/tab";
import EditVendor from "../../views/Vendors/Edit/edit";
import ColorList from "../../views/Colors/List/list";
import AddColor from "../../views/Colors/Add/add";
import SizeList from "../../views/Sizes/List/list";
import AddSize from "../../views/Sizes/Add/add";
import ColorViewTabs from "../../views/Colors/View/tab";
import EditProduct from "../../views/Products/Edit/edit";
import ProductViewTabs from "../../views/Products/View/tab";
import ProductList from "../../views/Products/List/list";
import AddProduct from "../../views/Products/Add/add";
import CategoryList from "../../views/Category/List/CategoryList";
import CategoryViewTabs from "../../views/Category/View/CategoryTab";
import CategoryEdit from "../../views/Category/Edit/CategoryEdit";
import CategoryAdd from "../../views/Category/Add/CategoryAdd";
import ClubRequestList from "../../views/Clubs/Request/request";
import BannerImageList from "../../views/BannerImage/List/list";
import EditProfile from "../../views/UserProfile/edit";
import EditVendorProfile from "../../views/VendorProfile/edit";
import EditClubProfile from "../../views/ClubProfile/edit";
import Protected from "../../views/protect";
import SettingView from "../../views/Setting/view";
import ReportListView from "../../views/Reports/List/list";
import ReportViewTabs from "../../views/Reports/View/tab";
import Feedback from "../../views/Feedback/List/list";
import OrderList from "../../views/Orders/list/list";
import OrderViewTabs from "../../views/Orders/view/OrderTabs";
import VendorInquiryList from "../../views/vendorInquiry/List/list";
import ClubRequestView from "../../views/Clubs/Request/view";


const AppContent = () => {

  const [user, setuser] = useState(null);

  useEffect(() => {
    setuser(localStorage.getItem("user"));
  }, []);

  return (
    <CContainer fluid className="p-0">
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {/* {routes.map((route, idx) => {
          
              // route.element && ( */}
                <Route path='/dashboard' element={<Protected element={Dashboard} loginPath="/" />} />
                <Route path='/users' element={<Protected element={UserList} loginPath="/" />} />
                <Route path='/user/Edit/:id' element={<Protected element={EditUser} loginPath="/" />} />
                <Route path='/user/view/:id' element={<Protected element={UserViewTabs} loginPath="/" />} />
                <Route path='/events' element={<Protected element={EventList} loginPath="/" />} /> 
                <Route path='/event/Edit/:id' element={<Protected element={EditEvent} loginPath="/" />} /> 
                <Route path='/event/View/:id' element={<Protected element={EventViewTabs} loginPath="/" />} /> 
                <Route path='/groups' element={<Protected element={GroupList} loginPath="/" />} /> 
                <Route path='/group/Edit/:id' element={<Protected element={EditGroup} loginPath="/" />} /> 
                <Route path='/group/View/:id' element={<Protected element={GroupViewTabs} loginPath="/" />} /> 
                <Route path='/clubs' element={<Protected element={ClubList} loginPath="/" />} /> 
                <Route path='/clubs/create' element={<Protected element={AddClub} loginPath="/" />} /> 
                <Route path='/club/View/:id' element={<Protected element={ClubViewTabs} loginPath="/" />} /> 
                <Route path='/club/Edit/:id' element={<Protected element={EditCLub} loginPath="/" />} /> 
                <Route path='/rides' element={<Protected element={RidesList} loginPath="/" />} /> 
                <Route path='/brands' element={<Protected element={BrandList} loginPath="/" />} /> 
                <Route path='/brand/Edit/:id' element={<Protected element={EditBrand} loginPath="/" />} /> 
                <Route path='/brand/View/:id' element={<Protected element={BrandViewTabs} loginPath="/" />} /> 
                <Route path='/vendors' element={<Protected element={VendorList} loginPath="/" />} /> 
                <Route path='/vendors/create' element={<Protected element={AddVendor} loginPath="/" />} /> 
                <Route path='/vendor/View/:id' element={<Protected element={VendorViewTabs} loginPath="/" />} /> 
                <Route path='/vendor/Edit/:id' element={<Protected element={EditVendor} loginPath="/" />} /> 
                <Route path='/colors' element={<Protected element={ColorList} loginPath="/" />} /> 
                <Route path='/colors/create' element={<Protected element={AddColor} loginPath="/" />} />  
                <Route path='/color/View/:id' element={<Protected element={ColorViewTabs} loginPath="/" />} /> 
                <Route path='/sizes' element={<Protected element={SizeList} loginPath="/" />} /> 
                <Route path='/sizes/create' element={<Protected element={AddSize} loginPath="/" />} />  
                <Route path='/products' element={<Protected element={ProductList} loginPath="/" />} /> 
                <Route path='/products/create' element={<Protected element={AddProduct} loginPath="/" />} /> 
                <Route path='/product/edit/:id' element={<Protected element={EditProduct} loginPath="/" />} /> 
                <Route path='/product/view/:id' element={<Protected element={ProductViewTabs} loginPath="/" />} /> 
                <Route path='/order/view/:id' element={<Protected element={OrderViewTabs} loginPath="/" />} /> 
                <Route path='/orders' element={<Protected element={OrderList} loginPath="/" />}/>

                <Route path='/categories' element={<Protected element={CategoryList} loginPath="/" />} /> 
                <Route path='/categories/create' element={<Protected element={CategoryAdd} loginPath="/" />} /> 
                <Route path='/category/View/:id' element={<Protected element={CategoryViewTabs} loginPath="/" />} /> 
                <Route path='/category/Edit/:id' element={<Protected element={CategoryEdit} loginPath="/" />} /> 
                <Route path='/clubRequests' element={<Protected element={ClubRequestList} loginPath="/" />} /> 
                <Route path='/clubRequests/view/:id' element={<Protected element={ClubRequestView} loginPath="/" />} /> 

                <Route path='/bannerImages' element={<Protected element={BannerImageList} loginPath="/" />} /> 
                <Route path='/admin/edit' element={<Protected element={EditProfile} loginPath="/" />} /> 
                <Route path='/vendor/edit' element={<Protected element={EditVendorProfile} loginPath="/" />} /> 
                <Route path='/club/edit' element={<Protected element={EditClubProfile} loginPath="/" />} /> 
                <Route path='/reports' element={<Protected element={ReportListView} loginPath="/" />} />
                <Route path='/reports/View/:id' element={<Protected element={ReportViewTabs} loginPath="/" />} /> 
                <Route path='/setting' element={<Protected element={SettingView} loginPath="/" />} />
                <Route path='/vendor-inquiry' element={<Protected element={VendorInquiryList} loginPath="/" />} />
                <Route path='/feedback' element={<Protected element={Feedback} loginPath="/" />} />
        </Routes>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getVendorInquires } from "../../../helpers/Redux/api";
import Pagination from "react-mui-pagination";
import noimage from "../../../assets/images/users/noimg.png";
import Moment from "react-moment";
import './list.css'
const VendorInquiryList = () => {
    const [list, setList] = useState([]);
    const [total_count, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [recordperpage, setRecordperpage] = useState(10);
    const dispatch = useDispatch();

    const getVendorInquiryData = () => {
        const requestOption = {
            page: page,
            limit: recordperpage,
        };

        try {
            dispatch(getVendorInquires(requestOption)).then((res) => {
                console.log(res);
                if (res?.payload?.success === true) {
                    setList(res?.payload?.inquiries);
                    setTotalCount(res?.payload?.totalCount);
                } else {
                    setList([]);
                }
            });
        } catch (error) {
            console.log('Vendor Inquiry list Error:', error);
        }
    };

    useEffect(() => {
        getVendorInquiryData();
    }, [page]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <div className="list_page_container">
            <div className="page_name_heading_cont">
                <div className='container'>
                    <h5>Vendor Inquiry</h5>
                </div>
            </div>
            <div className="py-md-5">
                <div className="container">
                    <div className="shadow-box">
                        <div className="table_container table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Created at</th>
                                        <th>Email</th>
                                        <th>Mobile</th> {/* Added Mobile column */}
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list?.map((res, i) => (
                                        <tr key={i}>
                                            <td>{res?.name}</td>
                                            <td><Moment format="MM-DD-YYYY">{res?.created_at}</Moment></td>
                                            <td>{res?.email}</td>
                                            <td>{res?.mobileNo}</td> {/* Added Mobile number */}
                                            <td className="description-cell">{res?.description}</td> {/* Added class for styling */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {total_count > recordperpage && (
                            <div className="d-flex justify-content-end align-items-center my-3">
                                <Pagination
                                    page={page}
                                    setPage={handlePageChange}
                                    total={total_count}
                                    perPage={recordperpage}
                                    className='pagination'
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VendorInquiryList;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { viewOrderDetail, generateAWB } from "../../../../helpers/Redux/api";
import { toast } from "react-toastify";

const OrderInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [orderDetail, setInitialValue] = useState({});
  const previousPageCount = location.state?.page;
  const [loading, setLoading] = useState(true);
  const [awbLoading, setAwbLoading] = useState(false); // For AWB generation loading state

  const order_id = location.pathname?.split("/")[3];

  const handleGetData = () => {
    // console.log(order_id);
    setLoading(true);
    try {
      dispatch(viewOrderDetail(order_id)).then((res) => {
        console.log(res);
        if (res?.payload?.status === true) {
          setLoading(false);
          setInitialValue(res?.payload?.orderDetails[0]?.data);
        } else {
          setLoading(false);
          setInitialValue(orderDetail);
        }
      });
    } catch (error) {
      console.log(error, "from view Order details");
    }
  };

  const handleGenerateAWB = () => {
    setAwbLoading(true);
    dispatch(generateAWB({ shipment_id: orderDetail?.shipments?.id }))
      .then((res) => {
        console.log(res);
        setAwbLoading(false);
        if (res?.payload?.status === true) {
          toast.success("AWB generated successfully!");
          handleGetData();
        } else {
          toast.error("Failed to generate AWB.");
        }
      })
      .catch((error) => {
        setAwbLoading(false);
        console.error("Error generating AWB:", error);
        toast.error("Error generating AWB.");
      });
  };

  useEffect(() => {
    handleGetData();
  }, []);
  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('en-GB', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}     - ` + getFormattedTime(dateString);
  };
  
  
  const getFormattedTime = (isoString) => {
    const date = new Date(isoString);
    
        // Options for time formatting in 12-hour format with AM/PM
        const timeOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true // Use 12-hour format with AM/PM
        };
    
        // Format time
        return "    "+date.toLocaleTimeString('en-US', timeOptions);
  };

  return (
    <section className="add_section">
      <div className="view_information_cont_inner">
        {orderDetail?.channel_order_id && (
          <div className="information_details_cont">
            <label className="label">Order ID </label>:
            <p className="details ">{orderDetail?.channel_order_id}</p>
          </div>
        )}
        {orderDetail?.customer_name && (
          <div className="information_details_cont">
            <label className="label">Customer Name</label>:
            <p className="details ">{orderDetail?.customer_name}</p>
          </div>
        )}
        {orderDetail?.customer_email && (
          <div className="information_details_cont">
            <label className="label">Customer Email</label>:
            <p className="details ">{orderDetail?.customer_email}</p>
          </div>
        )}
        {orderDetail?.billing_mobile_country_code && (
          <div className="information_details_cont">
            <label className="label">Customer Phone</label>:
            <p className="details ">{orderDetail?.billing_mobile_country_code} {orderDetail?.customer_phone}</p>
          </div>
        )}
        {orderDetail?.order_date && (
          <div className="information_details_cont">
            <label className="label">Order Date </label>:
            <p className="details ">{orderDetail?.order_date || "NA"}</p>
          </div>
        )}
        {orderDetail?.total && (
          <div className="information_details_cont">
            <label className="label">Total Amount</label>:
            <p className="details ">{orderDetail?.total}</p>
          </div>
        )}
        {orderDetail?.status && (
          <div className="information_details_cont">
            <label className="label">Status</label>:
            <p className="details ">{orderDetail?.status}</p>
          </div>
        )}
        {orderDetail?.shipments?.shipped_date && (
          <div className="information_details_cont">
            <label className="label">Shipped Date</label>:
            <p className="details ">
              {orderDetail?.shipments?.shipped_date ? getFormattedDate(orderDetail?.shipments?.shipped_date) : "NA"}
            </p>
          </div>
        )}
        {orderDetail?.shipments?.delivered_date && (
          <div className="information_details_cont">
            <label className="label">Delivered Date</label>:
            <p className="details ">
              {orderDetail?.shipments?.delivered_date ? getFormattedDate(orderDetail?.shipments?.delivered_date) : "NA"}
            </p>
          </div>
        )}
        {orderDetail?.customer_address && (
          <div className="information_details_cont">
            <label className="label">Address</label>:
            <p className="details ">{orderDetail?.customer_address}</p>
          </div>
        )}
        {orderDetail?.customer_city && (
          <div className="information_details_cont">
            <label className="label">City</label>:
            <p className="details ">{orderDetail?.customer_city}</p>
          </div>
        )}
        {orderDetail?.customer_state && (
          <div className="information_details_cont">
            <label className="label">State</label>:
            <p className="details ">{orderDetail?.customer_state}</p>
          </div>
        )}
        {orderDetail?.customer_pincode && (
          <div className="information_details_cont">
            <label className="label">Pincode</label>:
            <p className="details ">{orderDetail?.customer_pincode}</p>
          </div>
        )}
        {orderDetail?.customer_country && (
          <div className="information_details_cont">
            <label className="label">Country</label>:
            <p className="details ">{orderDetail?.customer_country}</p>
          </div>
        )}
        <p className="btn btn-success mt-4" onClick={() => navigate("/orders", { state: { previousPageCount } })}>
          Back
        </p>
        <p
          className={`btn btn-primary mt-4 mx-3 ${orderDetail?.awb_data?.awb ? 'd-none' : 'd-inline-block'}`}
          onClick={handleGenerateAWB}
          disabled={orderDetail?.awb_data?.awb ? true : false}
        >
          {awbLoading ? "Generating AWB..." : "Generate AWB"}
        </p>

      </div>
    </section>
  );
};

export default OrderInfo;

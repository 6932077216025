import React from "react";
import hero_logo from "../../../assets/Homepage_image/hero-logo.svg";




import { useNavigate } from "react-router-dom";

const HomepageHome = () => {
    const navigate = useNavigate();
    return (
        <>
        <section className="hero-banner" id="hero">
        <div className="container">
            <div className="hero-banner-inner text-center">
                <h1>MYTRA is a platform for people of common interests to connect, find communities and grow businesses, in an exclusive space to <span>connect</span> and <span>grow</span>.</h1>
                <img src={hero_logo} alt="" className="img-fluid my-5" />
                {/* <div className="riders-love">
                    <p>Top riders <i class="fa fa-heart"></i> it!</p>
                </div> */}
            </div>
        </div>
    </section>
        </>
    )
}

export default HomepageHome;
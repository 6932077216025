import React, { useState, useEffect } from "react";
import './add.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { modelAdd } from "../../../helpers/Redux/api";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
//   width: 400,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};


const AddModel = ({setUpdateApi,brand_id  ,onClose ,open }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    // const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    console.log(brand_id)

    const [inputList, setInputList] = useState([{ modelName: "", modelStatus: "" }]);
    console.log(inputList)
    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

  // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        const remove = list.filter((_, indexFilter) => !(indexFilter === index));
        // list.splice(index, 1);
        setInputList(remove);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { modelName: "", modelStatus: "" }]);
    };
        
    const handleSubmit = () => {

       
        
        // Create request options with the FormData
        const requestOptions = {
            brand_id: brand_id,
            body: inputList.map((res) => ({
                model_name: res.modelName,
                status: res.modelStatus
            }))
        };

        try {
            dispatch(modelAdd(requestOptions)).then((res) => {
                if (res?.payload?.success === true) {
                    toast.success(res?.payload?.message);
                    // handleClose()
                    setUpdateApi("add")
                    onClose();
                } else {
                    toast.error(res?.payload?.message);
                }
            });

        } catch (error) {
            console.log(error , 'error')
        }
    }
    
    return (
        <>
            <div className="">
                {/* <button className="add_customization_btn" onClick={handleOpen} >Add</button> */}
            </div>
            <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style} className="brandModel">
                    <IoIosCloseCircleOutline className="close-icon" onClick={()=>onClose()} /> 
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Model
                    </Typography>
                    <div className="addModelMain">
                        {inputList.map((x, i) => {
                            return (
                            <div className="box">
                                <div className="boxInner">
                                    <input type="text" name="modelName" placeholder="Enter model name" value={x.modelName} onChange={(e) => handleInputChange(e, i)} />
                                    <input type="text" name="modelStatus" placeholder="Enter Status" value={x.modelStatus} onChange={(e) => handleInputChange(e, i)} />
                                    <div className="close-brand-field">
                                        {inputList.length !== 1 && (
                                            <button className="mr10" onClick={() => handleRemoveClick(i)}><IoIosCloseCircleOutline /> </button>
                                        )}
                                    </div>
                                </div>
                                <div className="btn-box text-end">
                                    {inputList.length - 1 === i && (
                                        <a className="btn btn-info mt-3 btn-sm" onClick={handleAddClick}>Add More</a>
                                    )}
                                </div>
                            </div>
                            );
                        })}
                    </div> 

                    <div className="brandModelFooter border-top pt-4 mt-4">
                        <a className="btn btn-secondary me-3" onClick={onClose}>Cancel</a>
                        <a className="btn btn-success" onClick={handleSubmit}>Save</a>
                    </div>
                </Box>
            </Modal>
       
        </>
    )
}

export default AddModel;
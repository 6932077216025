import React from 'react';

const Policy = () => {
  return (
    <div>
    <div className="top_section">
    <div className="container">
      <div className="title text-center">
        <strong>PRIVACY POLICY
</strong>
      </div>
      </div>

      </div>
      <div className=" container content_text_body"> 
      <div className="subtitle my-5 ">
        <strong>Last updated</strong> <strong>June 25, 2024</strong>
      </div>
      
      <p className='body_text' >This privacy notice for INITX INNOVATIONS PVT LTD. (doing business as MYTRA™) ('we', 'us', or 'our'), describes how and why we might collect, store, use, and/or share ('process') your information when you use our services ('Services'), such as when you:</p>
      <ul>
        <li className='body_text'>Visit our website at <a href="https://www.mytra.club">https://www.mytra.club</a>, or any website of ours that links to this privacy notice</li>
        <li className='body_text'>Download and use our mobile application (MYTRA™), or any other application of ours that links to this privacy notice</li>
        <li className='body_text'>Engage with us in other related ways, including any sales, marketing, or events</li>
      </ul>
      <p className='body_text'>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="#">support@mytra.club.</a></p>

      <h2 className='heading_1' >SUMMARY OF KEY POINTS</h2>
      <p className='body_text'>
      This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our <a href="#">table of contents</a> below to find the section you are looking for.</p>
      <ul>
        <li className='body_text'><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about <a href="#">personal information you disclose to us.</a></li>
        <li className='body_text'><strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.</li>
        <li className='body_text'><strong>Do we collect any information from third parties?</strong> We may collect information from public databases, marketing partners, social media platforms, and other outside sources. Learn more about <a href="#">information collected from other sources.</a> </li>
        <li className='body_text'><strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about <a href="#">how we process your information.</a></li>
        <li className='body_text'><strong>In what situations and with which types of parties do we share personal information?</strong> We may share information in specific situations and with specific categories of third parties. Learn more about <a href="#">when and with whom we share your personal information.</a></li>
        <li className='body_text'><strong>How do we keep your information safe?</strong> We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about <a href="#">how we keep your information safe.</a></li>
        <li className='body_text'><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about <a href="#">your privacy rights.</a></li>
        <li className='body_text'><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by submitting a <a href="#">data subject access request</a>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</li>
        <li className='body_text'>Want to learn more about what we do with any information we collect? <a href="#">Review the privacy notice in full.</a></li>
      </ul>

      <h2 className='MsoNormal heading_1 mt-4'>TABLE OF CONTENTS</h2>
      <ul>
        <li><a href="#section1">WHAT INFORMATION DO WE COLLECT?</a></li>
        <li><a href="#section2">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
        <li><a href="#section3">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
        <li><a href="#section4">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
        <li><a href="#section5">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></li>
        <li><a href="#section6">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
        <li><a href="#section7">HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
        <li><a href="#section8">DO WE COLLECT INFORMATION FROM MINORS?</a></li>
        <li><a href="#section9">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
        <li><a href="#section10">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
        <li><a href="#section11">DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
        <li><a href="#section12">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
        <li><a href="#section13">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
      </ul>

      {/* Sections 1-13 */}
      {/* Repeat similarly for each section using the data provided */}

      <h2 className='MsoNormal heading_1 mt-4' id='section1'>1. WHAT INFORMATION DO WE COLLECT?</h2>

<strong>Personal information you disclose to us</strong>
<p className='body_text my-2 ' ><strong>In Short:</strong> We collect personal information that you provide to us.</p>
<p className='body_text'>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>

<strong>Personal Information Provided by You</strong>
<p className='body_text' >The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
<ul>
  <li className='paymentListItem' >Names</li>
  <li className='paymentListItem' >Phone numbers</li>
  <li className='paymentListItem' >Email addresses</li>
  <li className='paymentListItem' >Usernames</li>
  <li className='paymentListItem' >Passwords</li>
  <li className='paymentListItem' >Contact preferences</li>
  <li className='paymentListItem' >Contact or authentication data</li>
  <li className='paymentListItem' >Billing addresses</li>
  <li className='paymentListItem' >Debit/credit card numbers</li>
  <li className='paymentListItem' >Date of birth</li>
  <li className='paymentListItem' >Photos</li>
</ul>

<p className='heading_1 my-2 mt-4 ' >Sensitive Information</p>
<p className='body_text' >We do not process sensitive information.</p>

<h4>Payment Data</h4>
<p className='body_text'>We may collect data necessary to process your payment if you choose to make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is handled and stored by Razorpay Software Private Limited. You may find their privacy notice link(s) here: <a href="https://razorpay.com/privacy/" target="_blank" rel="noopener noreferrer">https://razorpay.com/privacy/</a>.</p>

<h4>Social Media Login Data</h4>
<p className='body_text'> We may provide you with the option to register with us using your existing social media account details, like your Facebook, X, or other social media account. If you choose to register in this way, we will collect certain profile information about you from the social media provider, as described in the section called 'HOW DO WE HANDLE YOUR SOCIAL LOGINS?' below.</p>

<h4>Application Data</h4>
<p className='body_text'>If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>
<ul>
  <li className='paymentListItem'><strong>Geolocation Information:</strong> We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.</li>
  <li className='paymentListItem'><strong>Mobile Device Access:</strong> We may request access or permission to certain features from your mobile device, including your mobile device's camera, contacts, sensors, sms messages, storage, and other features. If you wish to change our access or permissions, you may do so in your device's settings.</li>
  <li className='paymentListItem'><strong>Push Notifications:</strong> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.</li>
</ul>
<p className='body_text'>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>

<p className='body_text'>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

<h3>Information collected from other sources</h3>
<p className='body_text' ><strong>In Short:</strong>We may collect limited data from public databases, marketing partners, social media platforms, and other outside sources.</p>
<p className='body_text'>In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, social media platforms, and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behaviour data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion.</p>
<p className='body_text'>If you interact with us on a social media platform using your social media account (e.g. Facebook or X), we receive personal information about you from such platforms such as your name, email address, and gender. Any personal information that we collect from your social media account depends on your social media account's privacy settings. Please note that their own use of your information is not governed by this privacy notice.</p>

<div className='heading_1' id='section2'>2. HOW DO WE PROCESS YOUR INFORMATION?</div>
<p className='body_text mt-2'><strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>
<p className='body_text'>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
<ul>
  <li className='paymentListItem'><strong>To facilitate account creation and authentication and otherwise manage user accounts:</strong> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
  <li className='paymentListItem'><strong>To deliver and facilitate delivery of services to the user:</strong> We may process your information to provide you with the requested service.</li>
  <li className='paymentListItem'><strong>To respond to user inquiries/offer support to users:</strong> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
  <li className='paymentListItem' ><strong>To fulfil and manage your orders:</strong> We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services.</li>
  <li className='paymentListItem'><strong>To enable user-to-user communications:</strong> We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
  <li className='paymentListItem'><strong>To request feedback:</strong> We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
  <li className='paymentListItem'><strong>To deliver targeted advertising to you:</strong> We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more.</li>
  <li className='paymentListItem'><strong>To evaluate and improve our Services, products, marketing, and your experience:</strong> We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.</li>
  <li className='paymentListItem'><strong>To identify usage trends:</strong> We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
</ul>

<div className='heading_1 mt-4' id='section3'>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</div>
<p className='body_text mt-2'><strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following categories of third parties.</p>
<p className='body_text'><strong>Vendors, Consultants, and Other Third-Party Service Providers:</strong> We may share your data with third-party vendors, service providers, contractors, or agents ('third parties') who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organisation apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.</p>
<p className='body_text' >The categories of third parties we may share personal information with are as follows:</p>
<ul>
  <li className='paymentListItem'>Order Fulfilment Service Providers</li>
  <li className='paymentListItem'>Payment Processors</li>
  <li className='paymentListItem'>User Account Registration & Authentication Services</li>
</ul>
<p className='body_text'>We also may need to share your personal information in the following situations:</p>
<ul>
  <li className='paymentListItem' ><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
  <li className='paymentListItem'><strong>When we use Google Maps Platform APIs:</strong> We may share your information with certain Google Maps Platform APIs (e.g. Google Maps API, Places API). We obtain and store on your device ('cache') your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.</li>
  <li className='paymentListItem'><strong>Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</li>
  <li className='paymentListItem'><strong>Business Partners:</strong> We may share your information with our business partners to offer you certain products, services or promotions.</li>
  <li className='paymentListItem'><strong>Other Users:</strong> When you share personal information (for example, by posting comments, contributions or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</li>
  <li className='paymentListItem'><strong>Offer Wall.</strong>Our application(s) may display a third-party hosted 'offer wall'. Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.</li>
</ul>

<div className='heading_1 mt-4' id='section4'>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</div>
<p className='body_text mt-2'><strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.</p>
<p className='body_text'>We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.</p>
<p className='body_text'>We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.</p>
<p className='body_text'>Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>

<div className='heading_1 mt-4' id='section5'>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</div>
<p className='body_text mt-2'><strong>In Short:</strong> If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</p>
<p className='body_text'>Our Services offer you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</p>
<p className='body_text mt-2'>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>



<div className='heading_1 ' id='section6'>6. HOW LONG DO WE KEEP YOUR INFORMATION?</div>
<p className='body_text mt-2'><strong>In Short:</strong> We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</p>
<p className='body_text mt-2'>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than thirty six (36) months past the termination of the user's account.</p>
<p className='body_text mt-2'>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

<div className='heading_1 mt-4' id='section7'>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</div>
<p className='body_text mt-2'><strong>In Short:</strong> We aim to protect your personal information through a system of organisational and technical security measures.</p>
<p className='body_text mt-2'>We have implemented appropriate technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>

<div className='heading_1 mt-4' id='section8'>8. DO WE COLLECT INFORMATION FROM MINORS?</div>
<p className='body_text mt-2'><strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.</p>
<p className='body_text mt-2'>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <a href="mailto:privacy@ourapp.com">support@mytra.club.</a>.</p>

<div className='heading_1 mt-4' id='section9'>9. WHAT ARE YOUR PRIVACY RIGHTS?</div>
<p className='body_text mt-2'><strong>In Short:</strong> You may review, change, or terminate your account at any time,depending on your country, province, or state of residence.</p>
<p className='body_text'><strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <a href='mailto:privacy@ourapp.com'>'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a> below.</p>
<p className='body_text'>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
<h4>Account Information</h4>
<p className='body_text'>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>

<ul><li  className='body_text' >Log in to your account settings and update your user account.</li></ul>
<p className='body_text' >Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
<p className='body_text'>If you have questions or comments about your privacy rights, you may email us at <a href="#">support@mytra.club.</a></p>



<div className='heading_1 mt-4' id='section10' >10. CONTROLS FOR DO-NOT-TRACK FEATURES</div>
<p className='body_text mt-2'>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>



<div className='heading_1 mt-4 'id='section11' >11. DO WE MAKE UPDATES TO THIS NOTICE?</div>
<p className='body_text mt-2' > <strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
<p className='body_text mt-2'>We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>

<div className='heading_1 mt-4 'id='section12' >12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</div>
<p className='body_text mt-2 '>If you have questions or comments about this notice, you may email us at <a href="mailto:privacy@ourapp.com">support@mytra.club</a> or contact us by post at:

</p>
<div className="d-flex flex-column my-3" >
          <strong className="">INITX INNOVATIONS PRIVATE LIMITED</strong>
          <strong className="">Regulus Society, Balewadi</strong>
          <strong className="">Pune, Maharashtra 411045</strong>
          <strong className="">India</strong>
          </div>

<div className='heading_1 mt-4 'id='section13'>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</div>
<p className='body_text mt-2'>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information,  please fill out and submit a <a href="mailto:privacy@ourapp.com">data subject access request.</a>.</p>
<div className="mt-2 text-center " > © All Rights Reserved By <a href="https://mytra.club/"> INITX INNOVATIONS PVT. LTD. </a>  </div>

</div>
</div>

  );
};

export default Policy;

import {createSlice} from '@reduxjs/toolkit';
import * as API from './api'

const APIs = [API.login]


const userreducer  = createSlice({
    name:'user' , 
    initialState : {
        error :false , 
        data :[] , 
        isloading:false
    } , 
    extraReducers: (builder) => {
        APIs.forEach((api) => {
           
          builder
            .addCase(api.pending, (state, action) => {
              state.isloading = true;
            })
            .addCase(api.fulfilled, (state, action) => {
              state.isloading = false;
              state.data = action.payload;
            })
            .addCase(api.rejected, (state, action) => {
              state.isloading = false;
              state.error = true;
              state.data = action.payload;
            });
        });
      }
})

export default userreducer.reducer;

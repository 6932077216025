import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CategoryUpdate, viewCategoryDetail, } from "../../../helpers/Redux/api";
import { toast } from "react-toastify";

const CategoryEdit = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const previousPageCount = location.state?.page;


  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);


  const Category_Id = location.pathname?.split("/")[3];

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await fetch("http://111.118.252.246:6006/api/v1/vendor-list");
        if (!response.ok) {
          throw new Error("Failed to fetch vendors");
        }
        const responseData = await response.json();
        const data = responseData.data;
        setVendors(data); // Assuming data is an array of vendor objects with 'name' field
      } catch (error) {
        console.error("Error fetching vendors:", error);
        // Handle error fetching vendors
      }
    };

    fetchVendors();
  }, []);



  const [initialValue, setInitialValue] = useState({
    vendorName: '',
    categoryName: '',

  });
  console.log(initialValue)

  const handlegetdata = () => {
    setLoading(true);
    try {
      dispatch(viewCategoryDetail(Category_Id)).then((res) => {
        if (res?.payload?.status === true) {
          setLoading(false);


          setInitialValue({
            vendorName: res?.payload?.data.vendor_id,
            categoryName: res?.payload?.data.category_name,
          });

        } else {
          setLoading(false);
          setInitialValue(initialValue);
        }
      });
    } catch (error) {
      console.log(error, "from view Vendor details");
    }
  };
  useEffect(() => {
    handlegetdata();
  }, []);

  const handleSubmit = (values, { resetForm }) => {

    const requestOptions = {
      id: Category_Id,
      data: {


        vendor_id: values.vendorName,
        category_name: values.categoryName

      }

    };

    try {
      dispatch(CategoryUpdate(requestOptions)).then((res) => {
        console.log(res);
        if (res?.payload?.status === true) {
          toast.success(res?.payload?.message);
          navigate(-1);
          resetForm();
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log(error, 'error');
    }
  };


  return (
    <div className="edit-section">
      <div className="page_name_heading_cont">
        <div className='container'>
          <h5>Edit Category Details</h5>
        </div>
      </div>
      <div className='py-5'>
        <div className='container'>
          <div className="edit-form shadow-box">
            {!loading &&
              <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <Form>
                    <div className='row'>
                      <div className='col-md-6 mb-4'>
                        <label htmlFor="vendorName">Vendor Name</label>
                        <Field as="select" className="form-control" name="vendorName">
                          <option value="">Select Vendor</option>
                          {vendors.map((vendor, index) => (
                            <option key={index} value={vendor.id}>{vendor.name}</option>
                          ))}
                        </Field>
                      </div>
                      <div className='col-md-6 mb-4'>
                        <label htmlFor="categoryName">Category Name</label>
                        <Field className="form-control" placeholder="Your Category Name" name="categoryName" required="" />
                      </div>


                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-3">
                        <button type="submit" className="btn btn-success w-100 mt-4">Submit</button>
                      </div>
                      <div className="col-md-3">
                        <button type="button" className="btn btn-secondary w-100 mt-4" onClick={() => navigate("/categories", { state: { previousPageCount } })}>Back</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryEdit;
import React from "react";
import arrow_right from "../../../assets/Homepage_image/arrow-right.svg"
import logo_white from "../../../assets/Homepage_image/logo-white.svg"
import map from "../../../assets/Homepage_image/map.svg"
import envelope from "../../../assets/Homepage_image/envelope.svg"
import mbl from "../../../assets/Homepage_image/mbl.svg"
import google_play from "../../../assets/Homepage_image/google-play.svg"
import app_store from "../../../assets/Homepage_image/app-store.svg"
import google_play_qr from "../../../assets/Homepage_image/android-app.png"
import app_store_qr from "../../../assets/Homepage_image/appstore.png"
import { Link } from "react-router-dom";




const HomePageFooter  = () =>{
    return (
        <footer id="footer" className="pb-0">
            <div className="container">
                <div className="row footer_top_row align-items-center justify-content-between">
                    <div className="col-lg-6">
                        <h2 className="text-white">Want to onboard your brand?</h2>
                    </div>

                    <div className="col-lg-6">
                        {/* <div className="search-content">
                            <input type="email" className="form-control" placeholder="Write to us..."/>
                            <button className="dark-btn btn m-0" type="button">Get Ready <img src={arrow_right} alt="" className="img-fluid ms-md-4"/></button>
                        </div> */}

                        <div className="search-content">
                            <input type="email" className="form-control" placeholder="Write to us..."/>
                            <a href="mailto:support@mytra.club" className="dark-btn btn m-0">
                                Get Ready <img src={arrow_right} alt="" className="img-fluid ms-md-4"/>
                            </a>
                        </div>
                    </div>
                </div>
            
                <div className="row">
                    <div className="col-xl-4 col-md-6 mb-5 mb-lg-0">
                        <div className="footer-content">
                            <div className="mb-5" ><img src={logo_white} alt="" className="img-fluid " /><sup className="text-light" >TM</sup></div>
                            <p>We love to hear from our club members. Happy to get feedback or answer your queries.</p>
                            <p>connect with us by email <a href="mailto:support@mytra.club">support@mytra.club</a> or by support section in our app.</p>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6">
                        <div className="footer-links">
                            <h4 className="mb-md-5 mb-3">Contact Us</h4>
                            
                            <p><img src={map} alt="address" className="img-fluid" /> Pune, Maharashtra - 45</p>
                            <p><img src={mbl} alt="phone" className="img-fluid" /> Call +91 91586 00561</p>
                            <p><img src={envelope} alt="support" className="img-fluid" /> support@mytra.club</p>
                        </div>
                    </div>


                    <div className="col-xl-2 col-md-6">
                        <div className="footer-links">
                            <h4 className="mb-md-5 mb-3">Links</h4>
                            <ul>
                                <li><a href="#home-about">About</a></li>
                                <li><Link to="/returnpolicy">Return & Refund</Link></li>
                                <li> <Link to='/policy' > Privacy Policy</Link></li>
                                <li><Link to="/terms&conditions">Terms & Conditions</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6">
                        <div className="footer-links">
                            <h4 className="mb-md-5 mb-3">Install App</h4>
                            
                            <ul className="app-download list-inline">
                                <li className="list-inline-item"><a href="https://play.google.com/store/apps/details?id=com.thebikerscompany"><img src={google_play} alt="" className="img-fluid" /></a><img src={google_play_qr} alt="" className="img-fluid" width="120" height="120" /></li>
                                <li className="list-inline-item"><a href="https://apps.apple.com/in/app/mytra/id6526488238"><img src={app_store} alt="" className="img-fluid" /></a><img src={app_store_qr} alt="" className="img-fluid" width="120" height="120" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="footer_bottom_row">  
                <div className="container-fluid">
                    <div className="copyright-content">
                        <p>© 2024 All Rights Reserved  |  INITX INNOVATIONS PVT. LTD.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default HomePageFooter
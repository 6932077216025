import React from "react";
import community from "../../../assets/Homepage_image/community.png";
import confetti from "../../../assets/Homepage_image/confetti.svg";
import top_rated from "../../../assets/Homepage_image/top-rated.svg";
import events from "../../../assets/Homepage_image/events.png";
import routes from "../../../assets/Homepage_image/routes.png";
import fire_flame from "../../../assets/Homepage_image/fire-flame.svg";
import news from "../../../assets/Homepage_image/news.png";
import safa from "../../../assets/Homepage_image/safa.png";








import { useNavigate } from "react-router-dom";

const HomepageFeatures = () => {
    const navigate = useNavigate();
    return (
        <>
         <section id="mytra-features" className="pb-0">
        <div className="container">
            <div className="section-title text-center text-white">
                <h2>Mytra App Features</h2>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="feature-box">
                        <h6>Community <img src={top_rated} alt="" className="img-fluid"/></h6>
                        <div className="feature-box-inner">
                            <img src={community} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="feature-box">
                        <h6>Events <img src={confetti} alt="" className="img-fluid"/></h6>
                        <div className="feature-box-inner">
                            <img src={events} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="feature-box">
                        <h6> <a> Routes</a></h6>
                        <div className="feature-box-inner">
                            <img src={routes} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="feature-box">
                        <h6>News <img src={fire_flame} alt="" className="img-fluid"/></h6>
                        <div className="feature-box-inner">
                            <img src={news} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row device-image text-center mt-5 justify-content-center">
                <div className="col-xl-10 col-lg-7 col-md-8">
                    <img src={safa} alt="" className="img-fluid"/>
                </div>
            </div>
        </div>
    </section>
        </>
    )
}

export default HomepageFeatures;
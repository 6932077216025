import React, { useState, useEffect } from "react";
import noimage from "../../../assets/images/users/noimg.png"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { VendorLists, CategoriesList, CategoryStatusUpdate, CategoryDelete } from "../../../helpers/Redux/api";
import Tooltip from '@mui/material/Tooltip';
import Pagination from "react-mui-pagination";
import { IoMdEye } from "react-icons/io";
import { ImBin2 } from "react-icons/im";
import { MdModeEdit } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const CategoryList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [total_count, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [recordperpage, setRecordperpage] = useState(10);
    const [vendors, setVendors] = useState([]);
    const [categories, setCategories] = useState([]);
    const [ids, setIds] = useState("");
    const [search, setSearch] = useState("");

    useEffect(() => {
        if (localStorage.getItem("userType") == 'vendor') {
            setIds(localStorage.getItem("user_id"))
            if (ids) {
                // getVendorsData();
                getCategoryData();
            }

        } else {
            // getVendorsData();
            getCategoryData();
        }
       
    }, [page, search, ids]);
    const getVendorsData = () => {
        const requestOptions = {
            page: page,
            limit: recordperpage,
            search: search
        };
        try {
            dispatch(VendorLists(requestOptions)).then((res) => {
                if (res?.payload?.status === true) {
                    setVendors(res?.payload?.data);
                    setTotalCount(res?.payload?.totalCount);
                } else {
                    setVendors([]);
                }
            });
        } catch (error) {
            console.log('Vendor list Error :- ', error);
        }
    };

    const getCategoryData = () => {
        const requestOption = {
            page: page,
            limit: recordperpage,
            search: search,
            id: ids,
        };
        try {
            dispatch(CategoriesList(requestOption)).then((res) => {
                if (res?.payload?.status === true) {
                    setList(res?.payload?.data);
                } else {
                    console.log("Error in fetching categories");
                    setList([]);
                }
            });
        } catch (error) {
            console.log('Category list Error :- ', error);
        }
    };

    // const getVendorName = (vendorId) => {
    //     const vendor = vendors.find(vendor => vendor.id === vendorId);
    //     return vendor ? vendor.vendor_name : 'Unknown Vendor';
    // };

    // const getCategoryName = (categoryId) => {
    //     const category = list.find(category => category.id === categoryId);
    //     return category ? category.category_name : 'Unknown Category';
    // };


    const handleUpdateStatus = (status, id) => {
        const sendStatus = !status;
        const requestOptions = {
            id: id,
            status: sendStatus
        };
        try {
            dispatch(CategoryStatusUpdate(requestOptions)).then((res) => {
                if (res?.payload?.status === true) {
                    setList(prevList => prevList.map(item => {
                        if (item.id === id) {
                            return { ...item, is_active: sendStatus };
                        }
                        return item;
                    }));
                    toast.success(res?.payload?.message);
                } else {
                    toast.error(res?.payload?.message);
                }
            });
        } catch (error) {
            console.log(error, 'error');
        }
    };


    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure want to delete category?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0373FF",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete"
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    dispatch(CategoryDelete(id)).then((res) => {
                        if (res?.payload?.success === true) {
                            toast.success(res?.payload?.message);
                            setList(list.filter(elm => elm.id !== res?.payload?.deleted_id));
                        } else {
                            toast.error(res?.payload?.message);
                        }
                    });
                } catch (error) {
                    console.log(error, 'error');
                }
            }
        });
    };

    return (
        <>
            <div className="list_page_container">
                <div className="page_name_heading_cont">
                    <div className='container'>
                        <h5>Categories</h5>
                    </div>
                </div>
                <div className="py-md-5">
                    <div className="container">
                        <div className="shadow-box">
                            <div className="top_search d-md-flex justify-content-between text-md-end mb-4">
                                <button className="btn btn-success m-0" onClick={() => navigate('/categories/create')} > + Add Category</button>
                                <input className="mt-3 mt-md-0" type="text" placeholder="Type here..." onChange={(e) => setSearch(e.target.value)} value={search}></input>
                            </div>
                            <div className="table_container table-responsive">
                                <table className="table" >
                                    <thead>
                                        <tr>
                                            {/* <th>Photo</th> */}
                                            <th>Vendor Name</th>
                                            <th>Category Name</th>
                                            <th>Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list?.map((res, i) => (
                                            <tr key={i} >
                                                {/* <td> <img src={res?.vendor_id === vendors.id ? res.vendors.vendor_logo : noimage} alt="profile_image" className="user_profile_img " /></td> */}
                                                <td className="common_class_td_width">{res?.vendor_name}</td>
                                                <td className="common_class_td_width">{res?.category_name}</td>
                                                <td>
                                                    <Tooltip title="Click to change status">
                                                        <button className={`${res?.is_active ? 'active' : 'inactive'}`}
                                                            onClick={() => handleUpdateStatus(res?.is_active, res?.id)}>{res?.is_active ? 'Active' : 'Inactive'}</button>
                                                    </Tooltip>
                                                </td>
                                                <td>
                                                    <div className="action_button_cont">
                                                        <Tooltip title="Edit">
                                                            <button className="edit_btn" onClick={() => navigate(`/category/edit/${res?.id}`)}><MdModeEdit /></button>
                                                        </Tooltip>
                                                        <Tooltip title="View">
                                                            <button className="view_btn" onClick={() => navigate(`/category/view/${res?.id}`, { state: { page } })}><IoMdEye /></button>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <button className="delete_btn" onClick={() => handleDelete(res?.id)}><ImBin2 /></button>
                                                        </Tooltip>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {total_count >= 10 &&
                                <div className="d-flex   justify-content-end align-items-center my-3 ">
                                    <Pagination page={page} setPage={setPage}
                                        total={total_count}
                                        perPage={recordperpage}
                                        className='pagination' />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryList;

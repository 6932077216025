import React, { useEffect, useState } from "react";
import './members.css';
import noimage from "../../../../assets/images/users/noimg.png";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { viewEventDetail } from "../../../../helpers/Redux/api";

const EventMembers = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [registeredUsers , setMembers] = useState([])

    const eventId = location.pathname?.split("/")[3];

    const handlegetdata = () => {
    //   setLoading(true);
      try {
        dispatch(viewEventDetail(eventId)).then((res) => {
         
          if (res?.payload?.status === true) {
            // setLoading(false);
            setMembers(res?.payload?.payload[0]?.approveUser);
           
          } else {
            // setLoading(false);
            // setInitialValue(initialValue);
          }
        });
      } catch (error) {
        console.log(error, "from view Event details");
      }
    };
    useEffect(() => {
      handlegetdata();
    }, []);
    

  
    return (
        <>
        <div className="members-main">
            <div className="row">
                {
                    registeredUsers?.map((res) => (
                        <div className="col-md-4" onClick={()=> navigate(`/user/view/${res.id}`)} >
                            <div className="member-box">
                                <img src={res?.profile_picture ? `${res?.profile_picture}` : noimage} alt="profile_image" className="img-fluid"/>
                                <h4>{res.full_name}</h4>
                                <h5>{res.role}</h5>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
        

        <p className="btn btn-success mt-4" onClick={() => navigate("/events")}>Back</p>
        </>
    )
}

export default EventMembers;
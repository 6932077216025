import React, { useEffect, useState } from "react";
import './gallery.css';
import header_image_thumg from '../../../../assets/images/users/noimg.png';
import noimage from "../../../../assets/images/users/noimg.png";

import { SlideshowLightbox } from "lightbox.js-react";
import 'lightbox.js-react/dist/index.css';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { viewEventDetail } from "../../../../helpers/Redux/api";


const EventGallery = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showimage, setShowImage] = useState(header_image_thumg);
    const [members , setMembers] = useState([])
  
    
    const eventId = location.pathname?.split("/")[3];
    
    console.log(members)
  
  const handlegetdata = async () => {
    try {
        const res = await dispatch(viewEventDetail(eventId));
        if (res?.payload?.status === true) {
            setMembers(res?.payload?.gallery);
        } else {
            console.log('Failed to fetch event details');
        }
    } catch (error) {
        console.log(error, "from view Event details");
    }
};

useEffect(() => {
    handlegetdata();
}, [eventId]);

    return (
        <>
        <div className="members-main">
            <div className="row">
                {
                    members?.map((res) => (
                        <div className="col-md-4"  >
                            <div className="gallery-box">
                            < img src={res?.path ? res?.path : noimage} alt="gallery" className="img-fluid" />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>    
        </>
    )
}

export default EventGallery;
import React, { useState } from "react";
import "./add.css";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import header_image_thumg from "../../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";
import { clubAdd } from "../../../helpers/Redux/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";    
import * as moment from 'moment'

const AddClub = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const previousPageCount = location.state?.page;
    const [showimage, setShowImage] = useState(header_image_thumg);
    const [startDate, setStartDate] = useState(new Date());
    const [inputValue, setInputValue] = useState('mytra-');

    const [sendImage, setSendImage] = useState(null);
    console.log(sendImage  ,'sendImage')

    const handleImageUpload = (e) => {
        var file = e.target.files[0];
        console.log(file, 'file')
    setSendImage( e.target.files[0]);
        
    setShowImage(URL.createObjectURL(file));
    };

    const prefix = 'mytra-'


    const handleSubmit = (values, { resetForm }) => {
        console.log(sendImage,'sendImage')

        // Create a new FormData object
        const formData = new FormData();

        // Append form values to FormData
        formData.append('clubName', values.clubName);
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('userId', values.userId);
        formData.append('startDate', moment(values?.start_date).format('YYYY/MM/DD'))
        formData.append('registrationNumber', values.registrationNumber);
        formData.append('headquarterName', values.headquarterName);
        formData.append('address', values.address);
        formData.append('description', values.description);
        formData.append('chatRestriction', values.chatRestriction);
        formData.append('club_logo', sendImage); // Append the image file


        // Create request options with the FormData
        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: {
        'authorization' :`${localStorage.getItem('token')}`
                
            }
        };

        try {
            dispatch(clubAdd(requestOptions)).then((res) => {
                if (res?.payload?.status === true) {
                    toast.success(res?.payload?.message);
                    navigate(-1)
                    resetForm()
                } else {
                    toast.error(res?.payload?.message);
                }
            });

        } catch (error) {
            console.log(error , 'error')
        }
    }

    const validationscheme = Yup.object({
        clubName: Yup.string().required(" Club name is required"),
        email: Yup.string().required(" Email is required").email(" Invalid email address"),
        password: Yup.string().required("Password is required"),
        userId: Yup.string().required(" User ID is required"),
        // registrationNumber: Yup.string().required(" Registration number is required"),
        registrationNumber: Yup.string()
        .required("Registration number is required")
        .test(
          'starts-with-mytra',
          'Registration number must start with "mytra-" ',
          value => value?.startsWith('mytra-') && value.length > 6 // 6 is the length of 'mytra-'
        ),
        headquarterName: Yup.string().required(" City name is required"),
        address: Yup.string().required(" Address is required"),
        description: Yup.string().required(" Description is required"),
    });
    


    return (
      <div className="edit-section">
        <div className="page_name_heading_cont">
            <div className='container'>
                <h5>Add Club</h5>
            </div>
        </div>
        <div className='py-5'>
            <div className='container'>
                <div className="edit-form shadow-box">
                <Formik
                    initialValues={{
                        clubName: '',
                        email: '',
                        password: '',
                        userId: '',
                        startDate: '',
                        registrationNumber: 'mytra-',
                        headquarterName: '',
                        address: '',
                        description: '',
                        chatRestriction: 0,
                        clubLogo: '',
                    }}
                    onSubmit={ handleSubmit}
                    validationSchema={validationscheme}
                >
                    
                    {(formik)=> (
                    <Form>
                        <div className='row'>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="clubName">Club Name</label>
                                <Field className="form-control" placeholder="Your Club Name" name="clubName" />
                                <ErrorMessage name='clubName' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="email">Email</label>
                                <Field className="form-control" placeholder="Email" name="email" />
                                <ErrorMessage name='email' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="password">Password</label>
                                <Field className="form-control" placeholder="Password" name="password" />
                                <ErrorMessage name='password' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="userId">User ID</label>
                                <Field className="form-control" placeholder="Owner User ID" name="userId" />
                                <ErrorMessage name='userId' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="startDate">Start Date</label>
                                <DatePicker className="form-control w-100" selected={startDate} onChange={(date) => { formik.setFieldValue("startDate", date); setStartDate(date) }} dateFormat="yyyy-MM-dd" />  
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="registrationNumber">Registration Number <small className="fw-normal">(eg. mytra-xxx)</small></label>
                                {/* <Field className="form-control" placeholder="eg. mytra-xxx" name="registrationNumber"/>  */}
                                            <Field name="registrationNumber" id="exampleEmail" type="text" className="form-control"
                                                ref={(target) => {target.value = prefix}}
                                                    onChange={(e)=>{
                                                        const input = e.target.value
                                                        
                                                        formik.setFieldValue("registrationNumber" , e.target.value = prefix + input.substr(prefix.length))
                                                    }}
                                                />
                                <ErrorMessage name='registrationNumber' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'> 
                                <label htmlFor="headquarterName">City</label>
                                <Field className="form-control" placeholder="City" name="headquarterName" />
                                <ErrorMessage name='headquarterName' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="address">Address</label>
                                <Field className="form-control" placeholder="Address" name="address" />
                                <ErrorMessage name='address' component="div" className="text-danger" />
                            </div>
                            <div className='col-md-12 mb-4'>
                                <label htmlFor="description">Description</label>
                                <Field className="form-control" name="description" component="textarea" rows="4" placeholder="Description" />
                                <ErrorMessage name='description' component="div" className="text-danger" />
                            </div>
                            <div className="col-md-12">
                                <div className="d-flex flex-row gap-4 align-items-center ">
                                <label htmlFor="status">
                                    Chat Restriction {" "}
                                </label>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role='Switch'  id="flexSwitchCheckChecked" name="chatRestriction" checked={formik.values.chatRestriction == 1}  onChange={(e) =>  formik.setFieldValue('chatRestriction' ,e.target.checked ? 1 : 0)}  />
                                    <label className="form-check-label" for="flexSwitchCheckChecked"></label>
                                    </div>
                                </div>
                                {/* <ErrorMessage
                                    component="p"
                                    name="status"
                                    className="text-danger"
                                /> */}
                            </div>
                            <div className='col-md-6'>
                                <div className="upload_image_container">
                                    <img src={ showimage} alt="logo" className="img-fluid uploaded_image " />
                                    <label htmlFor="upload-photo1">Upload Photo</label>
                                    <input type="file" name="clubLogo" id="upload-photo1" onChange={handleImageUpload} accept=".pjg, .png, .jpeg" />
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-4 g-3">
                            <div className="col-md-3">
                                <button type="submit" name="back" className="btn btn-success w-100 mt-4">Submit</button>
                            </div>
                            <div className="col-md-3">
                                <button type="button" className="btn btn-secondary w-100 mt-4" onClick={() => navigate("/clubs", { state: { previousPageCount } })}>Back</button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>
                </div>
            </div>    
        </div>
      </div>
    );
};
export default AddClub;

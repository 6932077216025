import React, { useEffect, useState } from "react";
import noimage from "../../../assets/images/users/noimg.png"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UserDelete, GroupLists, groupStatusUpdate, GroupDelete } from "../../../helpers/Redux/api";
import Tooltip from '@mui/material/Tooltip';
import Pagination from "react-mui-pagination";
import { IoMdEye } from "react-icons/io";
import { ImBin2 } from "react-icons/im";
import { MdModeEdit } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";


const GroupList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [list , setList] = useState([])

    const [total_count , setTotalCount] = useState(0)
    const [page , setPage] = useState(1)
    const [recordperpage, setRecordperpage] = useState(10)
    const [search, setSearch] = useState("")

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    
    const handleUpdateStatus = ( status , id) =>{
        const sendStatus =( status == true ? false : true)

        var requestOptions = {
            group_id:id,
            is_active:sendStatus
        }
        try {
            dispatch(groupStatusUpdate(requestOptions)).then((res)=>{
               if(res?.payload?.status == true){
                   getUsersData();
                   toast.success(res?.payload?.message)
               } else {
                toast.error(res?.payload?.message)

               }
            }) 

        } catch (error) {
            console.log(error , 'error')
        }
    }
    
    const getUsersData = () =>{
        const requestOption = {
             page:page , 
            limit: recordperpage,
            search:search
        }
        try {
            dispatch(GroupLists(requestOption)).then((res)=>{
              
                if (res?.payload?.success === true) {
  
                    setList(res?.payload?.groups)
                    setTotalCount(res?.payload?.totalCount)
                }
                else {
                    setList([])
                }
            })

        } catch (error) {
            console.log('user list Error :- ', error)
        }
    }
    useEffect(() => {
        getUsersData();
    }, [page,search])
    
    const handleDelete = (id) =>{
        
        Swal.fire({
            title: "Are you sure want to delete group?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0373FF",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete "
          }).then((result) => {
            if (result.isConfirmed) {

                try {
                    dispatch(GroupDelete(id)).then((res)=>{
                       if(res?.payload?.success === true){
                        // Swal.fire({
                        //     title: "Deleted!",
                        //     text: "Member Deleted successfully.",
                        //     icon: "success"
                        //   });
                        toast.success(res?.payload?.message)

                        setList(list?.filter((elm)=> elm?.id != res?.payload?.deleted_id))
                       } else {
                        toast.error(res?.payload?.message)
                       }
                    }) 
        
                } catch (error) {
                    console.log(error , 'error')
                }
             
            }
          });

       
    }
    return (
        <div className="list_page_container">
            <div className="page_name_heading_cont">
                <div className='container'>
                    <h5>Groups</h5>
                </div>
            </div>
            <div className="py-md-5">
                <div className="container">
                    <div className="shadow-box">
                        <div className="top_search text-end mb-4">
                            <input type="text" placeholder="Type here..." onChange={(e)=> setSearch(e.target.value)} value={search}></input>
                        </div>
                        <div className="table_container table-responsive">
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th>Photo</th>
                                        <th>Group Name</th>
                                        <th>Owner Name</th>
                                        <th>Description </th>
                                        <th>Status</th>
                                        <th className="text-center" >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {  list?.map((res ,i)=>(
                                        <tr key={i} >
                                            <td> <img src={res?.image ? 
                                                res.image  
                                                : noimage} alt="profile_image" className="user_profile_img " />
                                            </td>

                                            <td>{res.name}</td>

                                            <td className="common_class_td_width">{res.owner_name}</td>
                                            <td className="common_class_nobreak_txt" > {res.description}</td>
                                            <td>
                                                <Tooltip title="Click to change status">
                                                <button className={`${res?.is_active ? 'active' : 'inactive'}`}
                                                onClick={() => handleUpdateStatus(res?.is_active , res?.id)}> {res?.is_active ? 'Active' :'Inactive'} </button>
                                                </Tooltip>
                                            </td>
                                            <td>
                                                <div className="action_button_cont">
                                                    <Tooltip title="Edit">
                                                        <button className="edit_btn" onClick={() => navigate(`/group/edit/${res?.id}`)} ><MdModeEdit /></button>
                                                    </Tooltip>
                                                    <Tooltip title="View">
                                                        <button className="view_btn" onClick={() => navigate(`/group/view/${res?.id}`, { state: { page } })} ><IoMdEye /></button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <button className="delete_btn" onClick={() => handleDelete(res?.id)} ><ImBin2 /></button>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        {total_count >= 10 && 
                            <div className="d-flex   justify-content-end align-items-center my-3 ">
                                <Pagination  page={page} setPage={handlePageChange}
                                total={total_count} 
                                perPage={recordperpage} 
                                className='pagination'  />
                            </div>
                        }
                    </div>    
                </div>
            </div>
        </div>
    );
}
  
  
  export default GroupList;
import React, { useState, useEffect } from "react";
import "./edit.css";
import * as Yup from "yup";
import { Field, ErrorMessage, Formik, Form } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import thumbimage from "../../assets/images/updateprofile/Ellipse1.png";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// import Header from "../../CommonInner/Header/header";
import FadeLoader from "react-spinners/FadeLoader";
import { ViewProfile, editVendorDetails } from "../../helpers/Redux/api";


const EditVendorProfile = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showimage, setShowImage] = useState(thumbimage);
  const [sendImage, setSendImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataloading, setDataLoading] = useState(false);
  const [showerror , setShowError] = useState(false)
  const [headerUpdate , setHeaderUpdate] = useState(0)




  const [oldPassword , setoldPassword] = useState('')
  const [newPassWord , setNewPassword] = useState('')
  const [confirmPassword , setConfirmPassword] = useState('')


  const [passswordInputType, setPasswordInputType] = useState(true);
  const [confirmPassswordInputType, setConfirmPasswordInputType] =
    useState(true);

  const [initializevalue, setInitialValue] = useState({
    user_name: "",
    email: "",
    
  });
  const validation = Yup.object({
    user_name: Yup.string().required("Name is required"),
    // last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address"),
    });
  
  
  
    const id = localStorage.getItem("user_id")

  const handlegetAdmindata = () => {
    setDataLoading(true);
    const requestOptions = {
      id: id,
      role: 1,
    }
    try {
      dispatch(ViewProfile(requestOptions)).then((res) => {
        if (res?.payload?.status === true) {
          setDataLoading(false);

          setInitialValue({
            user_name: res?.payload?.payload?.name,
            // last_name: res?.payload?.data?.last_name,
            email: res?.payload?.payload?.email,
          });
          if (res?.payload?.payload?.vendor_logo) {
            setShowImage(
              res?.payload?.payload?.vendor_logo
            );
            // setSendImage(res?.payload?.admin?.vendor_logo);
          }
        } else {
          setDataLoading(false);
          setInitialValue(initializevalue);
        }
      });
    } catch (error) {
      console.log(error, "Admin view");
    }
  };
  useEffect(() => {
    handlegetAdmindata();
  }, []);



  const handlUpdate = (values, { resetForm }) => {

    const formdata = new FormData();

    formdata.append("name", values?.user_name);
    // formdata.append("vendor_Id", vendor_Id);
    // formdata.append("old_password", oldPassword);
    // formdata.append("new_password", newPassWord);
    formdata.append("email", values?.email);
    formdata.append('vendor_logo', sendImage)

      var requestOptions = {
        id: id,
        data: {
      method: "POST",
      body: formdata,
      headers:{
        'authorization' :`${localStorage.getItem('token')}`
      }
    }
    };
    


    // if (newPassWord) {
      if ( newPassWord && (!oldPassword || !confirmPassword)) {
          setShowError(true);
          return false;
      } 
       if( newPassWord && ( newPassWord != confirmPassword)){
        return false
      } 
      else {
        setLoading(true);

    
        try{
            dispatch(editVendorDetails(requestOptions)).then((res) => {
              setLoading(false);
        
              if (res?.payload?.status == true) {
                toast.success(res?.payload?.message);
                setHeaderUpdate( headerUpdate + 1)
              } else {
                toast.error(res?.payload?.message);
              }
            }); } catch (error) {
              console.log(error)
            }
      }
  }
  // };


  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSendImage(file);
      const imageUrl = URL.createObjectURL(file);
      setShowImage(imageUrl);
    }
  };
  return (
    <>
      <div className="page_name_heading_cont">
          <div className='container'>
              <h5>Edit Profile</h5>
          </div>
      </div>
      <section className="edit_profile_cont p-0">
        <div className="container">
            <div className="edit-form shadow-box">
              <div className="login_page_right_side_box">
                {dataloading ? (
                  <p>Loading.....</p>
                ) : (
                  <Formik
                    initialValues={initializevalue}
                    validationSchema={validation}
                    onSubmit={handlUpdate}
                  >
                    {(formick , values) => (
                      <Form onSubmit={formick.handleSubmit}>
                        <div className='row'>
                            <div className='col-md-6 mb-4'>
                              <label htmlFor="user_name">Name <span className="text-danger">*</span></label>
                              <div className="w-100">
                                <Field type="text" name="user_name" className="form-control" id="user_name"/>
                                <ErrorMessage
                                  component="p"
                                  name="user_name"
                                  className="text-danger mb-0"
                                />
                              </div>
                            </div>
                            <div className='col-md-6 mb-4'>
                            <label htmlFor="email"> Email <span className="text-danger">*</span> </label>
                              <div className="w-100">
                                <Field type="email" name="email" className="form-control" id="email" />
                                <ErrorMessage
                                  component="p"
                                  name="email"
                                  className="text-danger mb-0"
                                />
                              </div>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-4 g-3">
                            <div className="col-md-3">
                                <button type="submit" isabled={loading == true} className="btn btn-success w-100 mt-4">
                                  {loading ? (
                                    <FadeLoader  className="fade_loader" color="#fff" height={7} margin={-8} radius={0} width={4}/>
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                            </div>
                        </div>

                        {/* <div className="form_container_sign_up ">
                          <div className="field_cont mt-2">
                            <div className=" c d-flex flex-column gap-1">
                              <label htmlFor="old_password">
                                Old Password
                              </label>
                              <div className="w-100">
                                <div className="password_field_cont_for_edit_profle">
                                  <input value={oldPassword}
                                    onChange={(e) => setoldPassword(e.target.value)}
                                    id="old_password"
                                    type={`${
                                      oldPassswordInputType ? "password" : "text"
                                    }`}
                                    className="input_field_profil_pass"
                                  />
                                  {oldPassswordInputType ? (
                                    <RiEyeCloseLine
                                      className="create_password_icons"
                                      onClick={() =>
                                        setOldPasswordInputType(false)
                                      }
                                    />
                                  ) : (
                                    <RxEyeOpen
                                      className="create_password_icons"
                                      onClick={() => setOldPasswordInputType(true)}
                                    />
                                  )}
                                </div>
                                {
                                  showerror && (oldPassword== '' || oldPassword == undefined || oldPassword == null)   ? <p className="text-danger mb-0" >Old Password is required</p> : ''
                                }
                               
                              </div>
                            </div>

                            <div className=" c d-flex flex-column gap-1">
                              <label htmlFor="new_password">
                               New Password
                              </label>
                              <div className="w-100">
                                <div className="password_field_cont_for_edit_profle">
                                  <input id="new_password"
                                    value={newPassWord}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    type={`${
                                      passswordInputType ? "password" : "text"
                                    }`}
                                    className="input_field_profil_pass"
                                  />
                                  {passswordInputType ? (
                                    <RiEyeCloseLine
                                      className="create_password_icons"
                                      onClick={() =>
                                        setPasswordInputType(false)
                                      }
                                    />
                                  ) : (
                                    <RxEyeOpen
                                      className="create_password_icons"
                                      onClick={() => setPasswordInputType(true)}
                                    />
                                  )}
                                </div>
                               
                              </div>
                            </div>
                            <div className=" c d-flex flex-column gap-1">
                              <label htmlFor="confirm_password">
                                Confirm Password
                              </label>
                              <div className="password_field_cont_for_edit_profle">
                                <input
                                  
                                  id="confirm_password"
                                  value={confirmPassword}
                                  onChange={(e) => setConfirmPassword(e.target.value)}
                                  type={`${
                                    confirmPassswordInputType
                                      ? "password"
                                      : "text"
                                  }`}
                                  className="input_field_profil_pass"
                                />
                                {confirmPassswordInputType ? (
                                  <RiEyeCloseLine
                                    className="create_password_icons"
                                    onClick={() =>
                                      setConfirmPasswordInputType(false)
                                    }
                                  />
                                ) : (
                                  <RxEyeOpen
                                    className="create_password_icons"
                                    onClick={() =>
                                      setConfirmPasswordInputType(true)
                                    }
                                  />
                                )}
                              </div>
                              {
                                  showerror && (confirmPassword== '' || confirmPassword == undefined || confirmPassword == null)   ? <p className="text-danger mb-0" >Confirm  Password is required</p> : ''
                                }
                               
                                {
                                 ( newPassWord !== confirmPassword) && confirmPassword  ? <p className="text-danger mb-0" >Password Must Match</p> : ''
                                }
                            </div>

                            
                          </div>
                        </div> */}
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            
            </div>
        </div>
      </section>
    </>
  );
};
export default EditVendorProfile;

import * as React from 'react';
import Box from '@mui/material/Box';
import './model.css'
import Modal from '@mui/material/Modal';
import { RxCross2 } from "react-icons/rx";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth:500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
  outline: 'none',
  height:'100%',
  maxHeight:500,
  minHeight:500,


  '@media (max-width: 767px)': {
    maxWidth: '90vw',
  },
};

export default function ProfilePhoto({src}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div className="view_image_container " onClick={() => handleOpen()} >  
          <img src={src} alt="logo" className="img-fluid uploaded_image"/>
      </div> 
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div className="profile_photo_cont">
                <div className='text-end' ><RxCross2 className='cross_model'    onClick={() => handleClose()}/></div>
                <div className="profile_photo_img_cont">
                    <img src={src} alt=""  className='profiler_images' />
                </div>
            </div>
        </Box>
      </Modal>
    </div>
  );
}

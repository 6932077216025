import React, { useEffect, useState } from "react";
import './CategoryInfo.css';
import header_image_thumg from "../../../../assets/images/users/noimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { viewCategoryDetail, viewVendorDetail } from "../../../../helpers/Redux/api";
import ProfilePhoto from "../../../helper/PhotoProfiler/model";

const CategoryInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [initialValue, setInitialValue] = useState({});
  const previousPageCount = location.state?.page;

  const [showimage, setShowImage] = useState(header_image_thumg);


  const [loading, setLoading] = useState(true);

  const category_id = location.pathname?.split("/")[3];

  const handlegetdata = () => {
    setLoading(true);
    try {
      dispatch(viewCategoryDetail(category_id)).then((res) => {
        if (res?.payload?.status === true) {
          setLoading(false);
          setInitialValue(res?.payload?.data);
          if (res?.payload?.data?.vendor_logo) {
            setShowImage(
              res?.payload?.data?.vendor_logo
            );
          }
        } else {
          setLoading(false);
          setInitialValue(initialValue);
        }
      });
    } catch (error) {
      console.log(error, "from view Vendor details");
    }
  };
  useEffect(() => {
    handlegetdata();
  }, []);

  return (
    <section className="add_section">
      <div className="view_information_cont_inner">
        {initialValue?.category_name && (
          <div className="information_details_cont">
            <label className="label">Name </label>:
            <p className="details ">{initialValue?.category_name}</p>
          </div>
        )}
        {initialValue?.email && (
          <div className="information_details_cont">
            <label className="label ">Email</label>:
            <p className="details ">{initialValue?.email}</p>
          </div>
        )}
        {initialValue?.mobile && (
          <div className="information_details_cont">
            <label className="label ">Mobile </label>:
            <p className="details ">
              {initialValue?.mobile
                ? `${initialValue?.mobile}` : "NA"}
            </p>
          </div>
        )}
        {initialValue?.zip_code && (
          <div className="information_details_cont">
            <label className="label ">Zipcode</label>:
            <p className="details ">{initialValue?.zip_code}</p>
          </div>
        )}
        {initialValue?.address && (
          <div className="information_details_cont">
            <label className="label ">Address</label>:
            <p className="details ">
              {initialValue?.address ? initialValue?.address : "NA"}
            </p>
          </div>
        )}
        {initialValue?.gst_no && (
          <div className="information_details_cont">
            <label className="label ">GST No.</label>:
            <p className="details ">{initialValue?.gst_no}</p>
          </div>
        )}
        {initialValue?.pan_card && (
          <div className="information_details_cont">
            <label className="label ">Pan No.</label>:
            <p className="details ">{initialValue?.pan_card}</p>
          </div>
        )}
        <div className="information_details_cont">
          <label className="label ">Status </label>:{" "}
          <p className="details ">
            {initialValue?.is_active == "1" ? "Active" : "Inactive"}
          </p>
        </div>
        {initialValue?.gender && (
          <div className="information_details_cont">
            <label className="label ">Gender</label>:
            <p className="details ">{initialValue?.gender}</p>
          </div>
        )}

        {initialValue?.user_name && (
          <div className="information_details_cont">
            <label className="label">User Name </label>:
            <p className="details ">{initialValue?.user_name}</p>
          </div>
        )}
        {initialValue?.bio && (
          <div className="information_details_cont">
            <label className="label">Bio </label>:
            <p className="details ">{initialValue?.bio}</p>
          </div>
        )}
        <div className="information_details_cont ">
          <label className="label">Photo</label>:
          <div className="view_image_container ">
            <ProfilePhoto src={showimage} />
          </div>
        </div>
      </div>
      <p className="btn btn-success mt-4" onClick={() => navigate("/categories", { state: { previousPageCount } })}>Back</p>
    </section>
  );
};
export default CategoryInfo;

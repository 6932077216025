import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CategoryAdd, VendorLists, viewVendorDetail } from "../../../helpers/Redux/api";

const CategoriesAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [vendors, setVendors] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);

  const previousPageCount = location.state?.previousPageCount;

  const fetchVendors = useCallback(async (page, search = '') => {
    setLoading(true);
    try {
let response;
      if(localStorage.getItem('userType')=='admin'){
        response = await dispatch(VendorLists({ page, limit: 10, search }));
      }
      else{
        response = await dispatch(viewVendorDetail(localStorage.getItem("user_id")));
      }
      const responseData = await response.payload;

      if (responseData.data && responseData.data.length > 0) {
        setVendors(prevVendors => [...prevVendors, ...responseData.data]);
        if(localStorage.getItem('userType')=='admin'){
          setHasMore(responseData.data.length === 10);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching vendors:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  // Fetch initial vendors when the component mounts
  useEffect(() => {
    fetchVendors(page);
  }, [fetchVendors, page]);

  // Scroll event handler to load more vendors
  const handleScroll = () => {
    if (containerRef.current) {
      const bottom = containerRef.current.getBoundingClientRect().bottom <= window.innerHeight;
      if (bottom && !loading && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleSubmit = (values, { resetForm }) => {
    console.log( values.vendorName);
    console.log( values.categoryName);
    const requestOptions = {
      vendor_id: values.vendorName,
      category_name: values.categoryName
    };
    dispatch(CategoryAdd(requestOptions)).then((res) => {
      console.log(res);
      if (res?.payload?.status === true) {
        toast.success(res?.payload?.message);
        navigate(-1);
        resetForm();
      } else {
        toast.error(res?.payload?.message || "Failed to add category");
      }
    }).catch(error => {
      console.error('Error adding category:', error);
    });
  };

  const validationSchema = Yup.object({
    vendorName: Yup.string().required("Vendor name is required"),
    categoryName: Yup.string().required("Category is required"),
  });

  return (
    <div className="edit-section">
      <div className="page_name_heading_cont">
        <div className='container'>
          <h5>Add Category</h5>
        </div>
      </div>
      <div className='py-5'>
        <div className='container'>
          <div className="edit-form shadow-box" ref={containerRef}>
            <Formik
              initialValues={{
                vendorName: '',
                categoryName: '',
              }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {(formik) => (
                <Form>
                  <div className='row'>
                    <div className='col-md-6 mb-4'>
                      <label htmlFor="vendorName">Vendor Name</label>
                      <Field as="select" className="form-control" name="vendorName">
                        <option value="">Select Vendor</option>
                        {vendors.map((vendor, index) => (
                          <option key={index} value={vendor.id}>{vendor.name}</option>
                        ))}
                      </Field>
                      <ErrorMessage name='vendorName' component="div" className="text-danger" />
                    </div>
                    <div className='col-md-6 mb-4'>
                      <label htmlFor="categoryName">Category Name</label>
                      <Field className="form-control" placeholder="Your Category Name" name="categoryName" />
                      <ErrorMessage name='categoryName' component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="row justify-content-center mt-4 g-3">
                    <div className="col-md-3">
                      <button type="submit" name="back" className="btn btn-success w-100 mt-4">Submit</button>
                    </div>
                    <div className="col-md-3">
                      <button type="button" className="btn btn-secondary w-100 mt-4" onClick={() => navigate("/categories", { state: { previousPageCount } })}>Back</button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            {loading && <div className="text-center mt-3">Loading...</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesAdd;

import React, { useState, useEffect, useRef, useCallback } from "react";
import "./add.css";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { VendorLists, categoryList, colorAdd, viewVendorDetail } from "../../../helpers/Redux/api";
import namedColors from "color-name-list/dist/colornames.json";
import nearestColor from "nearest-color";
import { SketchPicker } from "react-color";

const AddColor = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const previousPageCount = location.state?.page;

    const [color, setColor] = useState("#000000");
    const [showPicker, setShowPicker] = useState(false);
    const [categoryList2, setCategoryList2] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(''); // For selected vendor
    const pickerRef = useRef(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);

    const fetchVendors = useCallback(async (page, search = '') => {
        setLoading(true);
        try {
            let response;
            if (localStorage.getItem('userType') === 'admin') {
                response = await dispatch(VendorLists({ page, limit: 10, search }));
            } else {
                response = await dispatch(viewVendorDetail(localStorage.getItem("user_id")));
            }
            const responseData = await response.payload;

            if (responseData.data && responseData.data.length > 0) {
                setVendors(prevVendors => [...prevVendors, ...responseData.data]);
                if (localStorage.getItem('userType') === 'admin') {
                    setHasMore(responseData.data.length === 10);
                }
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching vendors:", error);
        } finally {
            setLoading(false);
        }
    }, [dispatch]);

    const handleSubmit = (values, { resetForm }) => {
        const colors = namedColors.reduce(
            (o, { name, hex }) => Object.assign(o, { [name]: hex }),
            {}
        );

        const getColorName = nearestColor.from(colors);
        const { name } = getColorName(color);

        const requestOptions = {
            category_id: values.category,
            color_code: color,
            color_name: name,
            vendor_id: selectedVendor,
        };

        try {
            dispatch(colorAdd(requestOptions)).then((res) => {
                if (res?.payload?.status === true) {
                    toast.success(res?.payload?.message);
                    navigate('/colors');
                    resetForm();
                } else {
                    console.log(res);
                    toast.error(res?.payload?.message);
                }
            }).catch((err)=>{
                console.log(err);
            });
        } catch (error) {
            console.log(error, 'error');
        }
    };

    const getUsersData = () => {
        try {
            dispatch(categoryList()).then((res) => {
                console.log(res);
                if (res?.payload?.status === true) {
                    setCategoryList2(res?.payload?.data);
                } else {
                    setCategoryList2([]);
                }
            });
        } catch (error) {
            console.log('user list Error :- ', error);
        }
    };

    useEffect(() => {
        getUsersData();
        fetchVendors(page);
    }, [fetchVendors, page]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setShowPicker(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pickerRef]);

    const validationscheme = Yup.object({
        category: Yup.string().required("Category is required"),
        color: Yup.string().required("Color is required"),
    });

    return (
        <div className="edit-section">
            <div className="page_name_heading_cont">
                <div className="container">
                    <h5>Add Color</h5>
                </div>
            </div>
            <div className="py-5">
                <div className="container">
                    <div className="edit-form shadow-box">
                        <Formik
                            initialValues={{
                                category: '',
                                color: color,
                            }}
                            onSubmit={handleSubmit}
                            validationSchema={validationscheme}
                        >
                            {({ setFieldValue }) => (
                                <Form>
                                    <div className="row">
                                        <div className="col-md-6 mb-4">
                                            <label htmlFor="category">Category</label>
                                            <Field className="form-control" as="select" name="category">
                                                <option value="" label="Select Category" />
                                                {categoryList2.map((res, i) => (
                                                    <option value={res.id} label={res.category_name} key={i} />
                                                ))}
                                            </Field>
                                            <ErrorMessage name="category" component="div" className="text-danger" />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <div className="color_div">
                                                <label htmlFor="color">Choose Color</label>

                                                <div
                                                    style={{ cursor: "pointer" }}
                                                    onClick={(event) =>{
                                                        event.stopPropagation();
                                                        setShowPicker(!showPicker)
                                                    } }
                                                    className="position-relative"
                                                >
                                                    <div
                                                        className="position-absolute color-box"
                                                        style={{ backgroundColor: color }}
                                                    ></div>
                                                    <Field
                                                        type="text"
                                                        className="form-control text-end"
                                                        placeholder="Color code"
                                                        name="color"
                                                        value={color}
                                                        style={{ cursor: "pointer" }}
                                                        readOnly
                                                    />
                                                </div>
                                                <div
                                                    ref={pickerRef}
                                                    className="color-picker-container position-absolute"
                                                >
                                                    {showPicker && (
                                                        <SketchPicker
                                                            color={color}
                                                            onChange={(updatedColor) => {
                                                                setColor(updatedColor.hex);
                                                                setFieldValue("color", updatedColor.hex);
                                                            }}
                                                            style={{ marginTop: 10 }}
                                                        />
                                                    )}
                                                </div>
                                                <ErrorMessage name="color" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label htmlFor="vendor">Vendor</label>
                                            <Field className="form-control" as="select" name="vendor" onChange={e => setSelectedVendor(e.target.value)}>
                                                <option value="" label="Select Vendor" />
                                                {vendors.map((vendor, index) => (
                                                    <option key={index} value={vendor.id}>{vendor.name}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="vendor" component="div" className="text-danger" />
                                        </div>
                                       
                                    </div>
                                    <div className="row justify-content-center mt-4 g-3">
                                        <div className="col-md-3">
                                            <button type="submit" className="btn btn-success w-100 mt-4">
                                                Submit
                                            </button>
                                        </div>
                                        <div className="col-md-3">
                                            <button
                                                type="button"
                                                className="btn btn-secondary w-100 mt-4"
                                                onClick={() => navigate("/colors", { state: { previousPageCount } })}
                                            >
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddColor;

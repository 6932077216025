import React from "react";
import logo from "../../../assets/Homepage_image/logo.svg"
const ReturnPolicy = () => {
  return (
    <div>
        <div className="top_section">
                <div className="container">
      <div className="title text-center">
        <strong>MYTRA Return and Refund Policy
</strong>
      </div>
      </div>
      </div>
      
      <div>
      
  <div className=" container content_text_body">
       

        <div className="heading_2 mt-3 "  >
          <strong>Introduction</strong>
        </div>
        <div className="body_text mt-3">
                Welcome to MYTRA. Our Return and Refund Policy outlines the terms and conditions for returns and refunds, emphasizing our commitment to customer satisfaction.
        </div>

  <div className="heading_2 mt-3">
          <strong>Eligibility and Timeframe
</strong>
        </div>
        <div className="body_text mt-3">
        To be eligible for a return, items must be unused and in the same condition as received. Customers have 7 days to initiate a return.


  </div>



  <div className="heading_2 mt-3">
          <strong>Condition of Items
</strong>
        </div>
        <div className="body_text mt-3">
        Returned items must be in their original packaging, with tags and labels intact.


  </div>

  <div className="heading_2 mt-3">
          <strong>Refund Process
</strong>
        </div>
        <div className="body_text mt-3">
        Refunds will be processed within 5 days of receiving the returned item. The refund will be issued to the original payment method.


  </div>

  <div className="heading_2 mt-3">
          <strong>Exceptions</strong>
        </div>
        <div className="body_text mt-3">
        Certain items, such as personalized or non-returnable goods, are exempt from being returned.


  </div>

  <div className="heading_2 mt-3">
          <strong>Disclaimer</strong>
        </div>
        <div className="body_text mt-3">
        For all products, the returns/ policy provided on the product page shall prevail over the general returns policy. Do refer the respective item's applicable return policy on the product page for any exceptions to this returns policy
  </div>

  <div className="heading_2 mt-3">
          <strong>Contact Information
</strong>
        </div>
        <div className="body_text mt-3">
        For inquiries related to returns and refunds, contact our customer support at support@mytra.club.


  </div>
  <div className="heading_2 mt-3">
          <strong>Policy Changes

</strong>
        </div>
        <div className="body_text mt-3">
        We reserve the right to update or modify the Return and Refund Policy at any time. Customers are encouraged to check the policy periodically for changes.


  </div>

  <div className="body_text mt-3">
  By purchasing with MYTRA™, customers acknowledge and agree to the terms outlined in this Return and Refund Policy.



  </div>
  <div className="my-4 text-center "> <img src={logo} alt="" /> </div>

  <div className="mt-2 text-center " > © All Rights Reserved By <a href="https://mytra.club/"> INITX INNOVATIONS PVT. LTD. </a>  </div>

  </div>
        
        
      </div>
    </div>
  );
};

export default ReturnPolicy;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import OrderInfo from './Information/OrderInfo';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  


export default function OrderViewTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="list_page_container">
    <div className="page_name_heading_cont">
      <div className='container'>
        <h5>Order Details</h5>
      </div>
    </div>
    <div className="py-5">
      <div className="container">
        <div className="shadow-box">
          <div className="custom_tabs">
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', }} className="pb-3">
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='view_tabs' TabIndicatorProps={{ style: { display: 'none' } }}>
                  <Tab label="Information" {...a11yProps(0)} className='tab' />
                  {/* <Tab label="Products" {...a11yProps(1)} className='tab' /> */}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <OrderInfo/>
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={1}>
                <CategoryProd />
              </CustomTabPanel> */}
            </Box>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

import React, { useState, useEffect } from "react";
import './dashboard.css';
import noimage from "../../assets/images/users/noimg.png";
import { FaRegUser } from 'react-icons/fa6';
import { FaUsers } from "react-icons/fa6";
import { GiFullMotorcycleHelmet } from "react-icons/gi";
import { HiOutlineUsers } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MdOutlineEventNote } from "react-icons/md";
import { RiMotorbikeFill } from "react-icons/ri";
import { DashboardDetails } from "../../helpers/Redux/api";



const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userList, setUserList] = useState([]);
    const [clubList, setClubList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [total, setTotal] = useState({
        totalClub: "",
        totalUser:"",
        totalVendor:"",
        totalGroup:"",
        totalEvents:"",
        totalPosts:"",

    });


    const [total_count, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [recordperpage, setRecordperpage] = useState(10);
    const [search, setSearch] = useState("");
    const [ids, setIds] = useState("");
    const [userType, setUserType] = useState("admin");
    

    const getUsersData = () =>{
        // const requestOption = {
        //      page:page , 
        //     limit: recordperpage,
        //     search: search,
        //     id: ids,
        // }
        try {
            dispatch(DashboardDetails(ids)).then((res)=>{
                if (res?.payload?.status === true) {
                    if (userType == "admin") {
                        setUserList(res?.payload?.data.latest_users)
                    setClubList(res?.payload?.data.upcoming_clubs)
                    setEventList(res?.payload?.data.upcoming_events)
                    setTotal({
                        totalClub: res?.payload?.data?.total_clubs,
                        totalUser:res?.payload?.data?.total_users,
                        totalVendor:res?.payload?.data?.total_vendors,
                        totalGroup:res?.payload?.data?.total_groups,
                        totalEvents:res?.payload?.data?.total_events,
                        totalPosts:res?.payload?.data?.total_posts,
                
                    })
                    }
                    else if (userType == "clubs") {
                        setUserList(res?.payload?.data.members_list)
                    setEventList(res?.payload?.data.events_list)
                    setTotal({
                        totalClub: res?.payload?.data?.total_events,
                        totalUser:res?.payload?.data?.club_member_count,
                
                    })
                    }
                    

                }
                else {
                    setUserList([])
                    setClubList([])
                    setEventList([])                }
            })

        } catch (error) {
            console.log('user list Error :- ', error)
        }
    }
    // useEffect(() => {
    //     getUsersData();
    //   }, []);


      useEffect(() => {
        setUserType(localStorage.getItem("userType"))
        if (localStorage.getItem("userType") == 'clubs') {
            setIds(localStorage.getItem("user_id"))
            if (ids) {
                getUsersData();
            }

        } else {
            getUsersData();
        }
       
      }, [page, search, ids]);
    

    
    return (
        <>
        <div className="page_name_heading_cont">
          <div className="container">
            <h5>Dashboard</h5>
          </div>
        </div>

        <div className="py-5">
            <div className="container">
                <div className="shadow-box shadow-none" style={{background: "#e3eff6"}}>
                    {userType == "admin" ? 
                    <div className="row gx-3">
                        <div className="col-xl-2 col-md-4 mb-4">
                            <div className="top-total-box text-center">
                                <h6 className="box-title">Total Users</h6>
                                    <h4 className="count-text"><FaRegUser /><span>{ total.totalUser? total.totalUser:0 }</span></h4>
                            </div>
                        </div>

                        <div className="col-xl-2 col-md-4 mb-4">
                            <div className="top-total-box text-center">
                                <h6 className="box-title">Total Clubs</h6>
                                <h4 className="count-text"><GiFullMotorcycleHelmet /><span>{ total.totalClub? total.totalClub:0 }</span></h4>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 mb-4">
                            <div className="top-total-box text-center">
                                <h6 className="box-title">Total Vendors</h6>
                                <h4 className="count-text"><HiOutlineUsers /><span>{ total.totalVendor? total.totalVendor:0 }</span></h4>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 mb-4">
                            <div className="top-total-box text-center">
                                <h6 className="box-title">Total Groups</h6>
                                <h4 className="count-text"><FaUsers /><span>{ total.totalGroup? total.totalGroup:0 }</span></h4>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 mb-4">
                            <div className="top-total-box text-center">
                                <h6 className="box-title">Total Events</h6>
                                <h4 className="count-text"><MdOutlineEventNote /><span>{ total.totalEvents? total.totalEvents:0 }</span></h4>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-4 mb-4">
                            <div className="top-total-box text-center">
                                <h6 className="box-title">Total Posts</h6>
                                <h4 className="count-text"><RiMotorbikeFill /><span>{ total.totalPosts? total.totalPosts:0 }</span></h4>
                            </div>
                        </div>
                    </div>
                    : userType == "clubs"?
                    <div className="row gx-3">
                        <div className="col-xl-6 col-md-6 mb-4">
                            <div className="top-total-box text-center">
                                <h6 className="box-title">Total Club Members</h6>
                                    <h4 className="count-text"><HiOutlineUsers /><span>{ total.totalUser? total.totalUser:0 }</span></h4>
                            </div>
                        </div>

                        <div className="col-xl-6 col-md-6 mb-4">
                            <div className="top-total-box text-center">
                                <h6 className="box-title">Total Club Events</h6>
                                <h4 className="count-text"><MdOutlineEventNote /><span>{ total.totalClub? total.totalClub:0 }</span></h4>
                            </div>
                        </div>
                    </div> 
                    : ""
                    
                    }

                   {
                    userType == "admin" || userType=="clubs" ?  <div className="row ">
                    <div className="col-xl-3 col-md-6 mb-4">
                        {userType == "admin" ? 
                        <div className="shadow-box">
                            <h5 className="box-title mb-4">Recent Users</h5>

                            <div className="scroller-div" >
                                {
                                    userList?.map((res) => (
                                        <div className="d-flex align-items-center avatar-box">
                                            <img src={res?.profile_picture ? `${res?.profile_picture}` : noimage} alt="profile_image" className="img-fluid me-3"/>
                                            <div className="avatar-content">
                                                <a onClick={()=> navigate(`/user/view/${res.id}`)}>{res.full_name}</a>
                                            </div>
                                        </div>
                                        
                                    ))
                                }
                            </div>
                        </div>
                        : userType == "clubs"?  
                        <div className="shadow-box">
                            <h5 className="box-title mb-4">Club Members</h5>

                            <div className="scroller-div" >
                                {
                                    userList?.map((res) => (
                                        <div className="d-flex align-items-center avatar-box">
                                            <img src={res?.profile_picture ? `${res?.profile_picture}` : noimage} alt="profile_image" className="img-fluid me-3"/>
                                            <div className="avatar-content">
                                                <a onClick={()=> navigate(`/user/view/${res.id}`)}>{res.full_name}</a>
                                            </div>
                                        </div>
                                        
                                    ))
                                }
                            </div>
                        </div>
                        : ""
                        }
                    </div>
                    
                    <div className={
                        userType === "admin" ? "col-xl-6 col-md-12 mb-4"
                        : userType === "clubs" ? "col-xl-9 col-md-12 mb-4"
                        : ""
                        }>
                    
                        <div className="shadow-box">
                            {userType == "admin" ? 
                            <h5 className="box-title mb-4">Upcoming Events</h5>
                            : userType == "clubs"?
                            <h5 className="box-title mb-4">Club Events</h5>
                            : ""
                            }
                            <div className="scroller-div" >
                            {
                                eventList?.map((res) => (
                                    <div className="up-event-box">
                                        <div className="d-md-flex align-items-center justify-content-between mb-3 up-event-box-top">
                                            <div className="d-flex align-items-center">
                                                <img src={res?.files[0]?.url ? `${res?.files[0]?.url}` : noimage} alt="profile_image" className="img-fluid me-3"/>
                                                <div className="customer-content">
                                                    <h5>{res.title} <span>{res.startTime}</span></h5>
                                                </div>
                                            </div>

                                            <div>
                                                <p>{res.startingPoint} - {res.destination}</p>
                                                <p>Total Kms : {res.totalKms}</p>
                                            </div>
                                        </div>

                                        <div className="up-event-box-des">
                                            <p>{res.description}</p>
                                            <a onClick={()=> navigate(`/event/view/${res._id}`)}>Read More...</a>
                                        </div>
                                        
                                    </div>
                                        
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-md-6 mb-4">
                        {userType == "admin" && 
                        <div className="shadow-box">
                            <h5 className="box-title mb-4">Top Clubs</h5>

                            <div className="scroller-div" >
                            {
                                clubList?.map((res) => (
                                    <div className="d-flex align-items-center avatar-box">
                                        <img src={res?.club_logo ? `${res?.club_logo}` : noimage} alt="profile_image" className="img-fluid me-3"/>
                                        <div className="avatar-content">
                                            <a onClick={()=> navigate(`/club/view/${res.id}`)}>{res.name}</a>
                                        </div>
                                    </div>
                                        
                                    ))
                                }
                            </div>
                        </div>
                        }
                    </div>
                </div>:""
                   }
                </div>
            </div>
        </div>
    </>
    )
}

export default Dashboard;
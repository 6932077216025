import React, {useState, useEffect} from "react";
// import './list.css';
import noimage from "../../../assets/images/users/noimg.png"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FeedbackList } from "../../../helpers/Redux/api";
import Pagination from "react-mui-pagination";
import Moment from "react-moment";



const Feedback = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [list, setList] = useState([])
    const [total_count , setTotalCount] = useState(0)
    const [page , setPage] = useState(1)
    const [recordperpage, setRecordperpage] = useState(10)
    const [search, setSearch] = useState("")
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getUsersData = () =>{
        const requestOption = {
             page:page , 
            limit: recordperpage,
            search: search,
            
        }
        try {
            dispatch(FeedbackList(requestOption)).then((res)=>{
                console.log(res);
                if(res?.payload?.status === true) {
                    setList(res?.payload?.data)
                    setTotalCount(res?.payload?.totalCount)
                }
                else {
                    setList([])
                }
            })

        } catch (error) {
            console.log('feedback list Error :- ', error)
        }
    }
    useEffect(() => {
        getUsersData();
    }, [page,search])
        
    return (
        <>
        {console.log(list)}
        <div className="list_page_container">
            <div className="page_name_heading_cont">
                <div className='container'>
                    <h5>Feedback</h5>
                </div>
            </div>
            <div className="py-md-5">
                <div className="container">
                    <div className="shadow-box">
                        <div className="table_container table-responsive">
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th>Photo</th>
                                        <th>Name</th>
                                        <th>Created at</th>
                                        <th>Email</th>
                                        <th>Feedback </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list?.map((res ,i)=>(
                                        <tr key={i} >
                                            <td> <img src={res?.profile_picture ? 
                                                res.profile_picture  
                                                : noimage} alt="profile_image" className="user_profile_img " />
                                            </td>

                                            <td className="" onClick={()=> navigate(`/user/view/${res.user_id}`)}>{res?.full_name}</td>
                                            <td className=""><Moment format="MM/DD/YYYY">{res?.created_at}</Moment></td>
                                            <td className="">{res?.email}</td>
                                            <td className="common_class_td_width">{res?.feedback_text}</td>
                                            {/* <td className="common_class_td_width">{res?.email}</td> */}
                                            
                                        </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        {total_count >= 10 && 
                            <div className="d-flex   justify-content-end align-items-center my-3 ">
                                <Pagination  page={page} setPage={handlePageChange}
                                total={total_count} 
                                perPage={recordperpage} 
                                className='pagination'  />
                            </div>
                        }
                    </div>    
                </div>
            </div>
        </div>
        </>
    )
}

export default Feedback;
import React, { useEffect, useState } from "react";
import './edit.css';
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import header_image_thumg from '../../../../src/assets/images/users/noimg.png';
import { useLocation, useNavigate } from "react-router-dom"; 
import { useDispatch } from "react-redux";
import { groupUpdate, viewGroupDetail } from "../../../helpers/Redux/api";
import { toast } from "react-toastify";
import * as moment from 'moment' 


const EditGroup = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const previousPageCount = location.state?.page;
    const [showimage, setShowImage] = useState(header_image_thumg);
    const [sendImage, setSendImage] = useState(null);
    const [status, setstatus] = useState(0);

    const [loading, setLoading] = useState(false);

    const groupId = location.pathname?.split("/")[3];

    
    const handleImageUpload = (e) => {
    let file = e.target.files[0];
    setShowImage(URL.createObjectURL(file));
    setSendImage(file);
    };

    const [initialValue, setInitialValue] = useState({
        name: '',
        description: '',
    });
    console.log(initialValue)

    const handlegetdata = () => {
        setLoading(true);
        try {
          dispatch(viewGroupDetail(groupId)).then((res) => {
            if (res?.payload?.status === true) {
              setLoading(false);
                setInitialValue({
                    groupName: res?.payload?.payload.name,
                    // ownerName: res?.payload?.payload.owner_name,
                    description: res?.payload?.payload.description,
              });
              if (res?.payload?.payload?.image) {
                setShowImage(
                  res?.payload?.payload?.image
                );
              }
            } else {
              setLoading(false);
              setInitialValue(initialValue);
            }
          });
        } catch (error) {
          console.log(error, "from view Group details");
        }
      };
      useEffect(() => {
        handlegetdata();
      }, []);
    
      const handleSubmit = (values, { resetForm }) => {
        console.log(sendImage, 'sendImage')
    
        // Create a new FormData object
        const formData = new FormData();
    
        // Append form values to FormData
        formData.append('name', values.groupName);
        formData.append('description', values.description);
        formData.append('group_logo', sendImage); // Append the image file
    
        // Create request options with the FormData
        const requestOptions = {
          groupId: groupId,
          body:{
            method: 'POST',
            body: formData,
            headers: {
            'authorization': `${localStorage.getItem('token')}`  
          }
          }
          
        };
    
        try {
          dispatch(groupUpdate(requestOptions)).then((res) => {
            if (res?.payload?.status === true) {
              toast.success(res?.payload?.message);
              navigate(-1)
              resetForm()
            } else {
              toast.error(res?.payload?.message);
            }
          });
    
        } catch (error) {
          console.log(error, 'error')
        }
      }
  
      const validationscheme = Yup.object({
        groupName: Yup.string().required(" Group name is required"),
        description: Yup.string().required("Description is required"),
      });
  
    return (
      <div className="edit-section">
        <div className="page_name_heading_cont">
            <div className='container'>
                <h5>Edit Group Details</h5>
            </div>
        </div>
        <div className='py-5'>
            <div className='container'>
                <div className="edit-form shadow-box">
                {!loading &&
                <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                validationSchema={validationscheme}
                >
                  {(formik)=> (
                    <Form>
                        <div className='row'>
                            <div className='col-md-6 mb-4'>
                                <label htmlFor="groupName">Group Name</label>
                                <Field className="form-control" placeholder="Group Name" name="groupName" />
                                <ErrorMessage name='groupName' component="div" className="text-danger" />
                            </div>
                            {/* <div className='col-md-6 mb-4'>
                                <label htmlFor="ownerName">Owner Name</label>
                                <Field className="form-control" name="ownerName" placeholder="Owner Name" />
                            </div> */}
       
                            <div className='col-md-12 mb-4'>
                                <label htmlFor="description">Description</label>
                                <Field className="form-control" name="description" component="textarea" rows="4" placeholder="Description" />
                                <ErrorMessage name='description' component="div" className="text-danger" />
                            </div>

                            <div className='col-md-6'>
                                <div className="upload_image_container">
                                    <img src={showimage} alt="logo" className="img-fluid uploaded_image " />
                                    <label for="upload-photo1">Upload Photo</label>
                                    <input type="file" name="" id="upload-photo1" onChange={handleImageUpload}
                                        accept=".pjg, .png, .jpeg" />
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <button type="submit" name="back" className="btn btn-success w-100 mt-4">Submit</button>
                            </div>
                            <div className="col-md-3">
                                <button type="button" className="btn btn-secondary w-100 mt-4" onClick={() => navigate("/groups", { state: { previousPageCount } })}>Back</button>
                            </div>
                        </div>
                    </Form>
                    )}
                </Formik>
                }
                </div>
            </div>    
        </div>
      </div>
    );
  }
  
  export default EditGroup;
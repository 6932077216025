import React from "react";
import header_logo from "../../../assets/Homepage_image/logo.svg";
import about_logo from "../../../assets/Homepage_image/about-logo.svg";
import google_play from "../../../assets/Homepage_image/google-play.svg";
import app_store from "../../../assets/Homepage_image/app-store.svg";
import about_device from "../../../assets/Homepage_image/about-device.svg";



import { useNavigate } from "react-router-dom";

const HomepageAbout = () => {
    const navigate = useNavigate();
    return (
        <>
        <section id="home-about">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="home-about-content">
                            <h3>About Us</h3>
                          <div className="d-flex align-items-center mb-3 gap-2 " >  <h2 className="">We Are</h2>  <div> <img src={about_logo} alt="" height="30" className="img-fluid" /> <sup>TM</sup> </div> </div>
                            <p>As our name suggests, MYTRA is an app for people. It is an exclusive space for bikers to connect with fellow bikers, join clubs, go on rides together, share experiences and grow the biker community.</p>
            
                            <p>Our team is a mix of riders, explorers and tech enthusiasts, and we are currently enjoying riding and testing the app, on our way to building something great.</p>
            
                            <div className="download-app-box my-5">
                                <h4>Download App</h4>
                                    
                                <ul className="list-inline mt-4">
                                    <li className="list-inline-item"><a href="https://play.google.com/store/apps/details?id=com.thebikerscompany"><img src={google_play} alt="" className="img-fluid" /></a></li>
                                    <li className="list-inline-item"><a href="https://apps.apple.com/in/app/mytra/id6526488238"><img src={app_store} alt="" className="img-fluid" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img src={about_device} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default HomepageAbout;
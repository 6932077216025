import React, { useEffect, useState } from "react";
import "./members.css";
import noimage from "../../../../assets/images/users/noimg.png";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { viewClubDetail, viewGroupDetail } from "../../../../helpers/Redux/api";

const GroupMembers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [members, setMembers] = useState([]);
  const previousPath = location.pathname;
  console.log(previousPath);
  const clubId = location.pathname?.split("/")[3];
  const previousPageCount = location.state?.page;
  const handlegetdata = () => {
    //   setLoading(true);
    try {
      dispatch(viewClubDetail(clubId)).then((res) => {
        if (res?.payload?.status === true) {
          // setLoading(false);
          setMembers(res?.payload?.data?.members);
        } else {
          // setLoading(false);
          // setInitialValue(initialValue);
        }
      });
    } catch (error) {
      console.log(error, "from view Group details");
    }
  };
  useEffect(() => {
    handlegetdata();
  }, []);

  return (
    <>
      <div className="members-main">
        <div className="row">
          {members?.map((res) => (
            <div
              className="col-md-4"
              onClick={() =>
                navigate(`/user/view/${res.user_id}`, {
                  state: { previousPageCount, previousPath },
                })
              }
            >
              <div className="member-box">
                {/* <img src="https://coding-server-s3bucket.s3.amazonaws.com/bikeRiderpublic/user/1718339340518-image.jpg" alt="profile_image" className="img-fluid" /> */}
                <img
                  src={
                    res?.profile_picture ? `${res?.profile_picture}` : noimage
                  }
                  alt="profile_image"
                  className="img-fluid"
                />
                <h4>{res.full_name}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>

      <p
        className="btn btn-success mt-4"
        onClick={() => navigate("/clubs", { state: { previousPageCount } })}
      >
        Back
      </p>
    </>
  );
};

export default GroupMembers;
